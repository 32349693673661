var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Button, useToast } from '@pocketlaw/tetris';
import { getMessageByStatus } from './getMessageByStatus';
import { getTitleByStatus } from './getTitleByStatus';
import { useAssistant } from '../../../AssistantProvider';
export function ResolvedAlert(props) {
    const { chatId, status } = props;
    const { addToast } = useToast();
    const { deleteChat } = useAssistant();
    const [deleting, setDeleting] = useState(false);
    const title = getTitleByStatus(status);
    const message = getMessageByStatus(status);
    const deleteConversation = () => __awaiter(this, void 0, void 0, function* () {
        setDeleting(true);
        try {
            yield deleteChat(chatId);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'We could not remove the conversation. Try again',
                    comment: 'Document assistant - error when trying to remove a conversation',
                }),
            });
        }
        setDeleting(false);
    });
    return (<Alert appearance="info" title={title} message={message}>
      <Button size="xs" appearance="secondary" disabled={deleting} onClick={deleteConversation}>
        <Trans comment="document assistant - resolved chat action - remove conversation label">
          Remove conversation
        </Trans>
      </Button>
    </Alert>);
}
