import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { Approvers } from './Approvers';
import { SectionContent, SectionFooter } from '../../components';
import { NoApproversAlert } from '../NoApproversAlert';
import { RejectedByApprover } from '../RejectedByApprover';
export function AllState(props) {
    const { onSubmit } = props;
    const { approvers } = useDocumentApproversWithStatus();
    const noAvailableApprovers = approvers.length === 0;
    const alertTitle = t({
        comment: 'Document requires approval state - Needs approval by all of the approvers - Info label',
        message: 'Approval required',
    });
    const alertMessage = t({
        comment: 'Document requires approval state - Needs approval by all of the approvers - Info message',
        message: 'This document needs approval by all of the assigned approvers before being sent for eSigning',
    });
    const handleSubmit = () => {
        onSubmit(approvers.map(approver => approver.userId));
    };
    return (<Fragment>
      <SectionContent>
        <RejectedByApprover />
        <Approvers />
      </SectionContent>
      <SectionFooter>
        {noAvailableApprovers ? (<NoApproversAlert />) : (<Alert appearance="info" title={alertTitle} message={alertMessage}/>)}
        <Button $stretch disabled={noAvailableApprovers} onClick={handleSubmit}>
          <Trans comment="Ask for approval button label">Ask for approval</Trans>
        </Button>
      </SectionFooter>
    </Fragment>);
}
