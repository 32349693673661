import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { AllState } from './AllState';
import { OneOfState } from './OneOfState';
import { RequestApprovalDialog } from './RequestApprovalDialog';
import { useDocumentApproversPermissions } from './useDocumentApproversPermissions';
import { OverlayLoader } from '../components/OverlayLoader';
export function ApprovalRequestState() {
    const { loading: loadingApprovers } = useDocumentApproversWithStatus();
    const { approversWithoutPermission, loading: loadingApproversPermissions } = useDocumentApproversPermissions();
    const { approvalWorkflow } = useEsigning();
    const { approvalType } = approvalWorkflow;
    const [showDialog, setShowDialog] = useState(false);
    const [selectedApproverUserIds, setSelectedApproverUserIds] = useState();
    const loading = loadingApprovers || loadingApproversPermissions;
    const loadingMessage = t({
        comment: 'Document requires approval state - Loading document approvers message',
        message: 'Loading document approvers...',
    });
    const handleSubmit = (approverUserIds) => {
        setSelectedApproverUserIds(approverUserIds);
        setShowDialog(true);
    };
    const handleClose = () => setShowDialog(false);
    if (loading) {
        return <OverlayLoader message={loadingMessage}/>;
    }
    return (<Fragment>
      {approvalType === ApprovalType.All ? (<AllState onSubmit={handleSubmit}/>) : (<OneOfState onSubmit={handleSubmit}/>)}
      {showDialog && (<RequestApprovalDialog approvalType={approvalType} selectedApproverUserIds={selectedApproverUserIds} approversWithoutPermission={approversWithoutPermission} onClose={handleClose}/>)}
    </Fragment>);
}
