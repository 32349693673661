import { t } from '@lingui/macro';
import { AvatarWithLabel, Switch } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField, useFormikContext } from 'formik';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { Container } from './styled';
function PublicField() {
    const account = useAccount();
    const { isSubmitting, values } = useFormikContext();
    const [field, , helpers] = useField({
        name: 'isPublic',
        type: 'checkbox',
    });
    const handleOnChange = (checked) => helpers.setValue(checked);
    const { userId } = account.data;
    const { creator } = values;
    const isNewTask = Boolean(!(creator === null || creator === void 0 ? void 0 : creator.id));
    const isCreator = creator && creator.id && creator.id === userId;
    const canEditPublic = isNewTask ? true : isCreator;
    if (!canEditPublic) {
        return null;
    }
    return (<Container>
      <AvatarWithLabel size="sm" appearance="light" title={t({
            message: 'Visible to everyone in your company',
            comment: 'Task public field text to toggle public task',
        })}>
        <Falcon icon="eye"/>
      </AvatarWithLabel>
      <Switch {...field} onCheckedChange={handleOnChange} disabled={isSubmitting}/>
    </Container>);
}
export { PublicField };
