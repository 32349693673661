var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t } from '@lingui/macro';
import { Box, IconButton, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantViewType } from 'app/domains/assistant/types';
import { useMobile } from 'shared/domains/common-ui';
import { useDocumentDrawer } from 'shared/domains/documents';
import { useAssistant } from '../AssistantProvider';
import { RemoveConversationModal } from '../RemoveConversationModal';
export function AssistantChatHeaderActions() {
    const { activeChat, view, initiating, hasCredits, isPendingAssistantMessage, cannotSubmitUserMessage, deleteChat, deletingChat, goToConversations, initiatingError, } = useAssistant();
    const { expanded, toggleExpanded } = useDocumentDrawer();
    const { addToast } = useToast();
    const isMobile = useMobile();
    const [renderDeleteDialog, setRenderDeleteDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const showDeleteChat = view === AssistantViewType.Chat;
    const showChatHistory = view !== AssistantViewType.Conversations;
    const disableDeleteChat = !hasCredits || isPendingAssistantMessage || cannotSubmitUserMessage || deleting;
    const deleteChatTooltip = t({
        comment: 'Document assistant actions - Remove conversation button tooltip label',
        message: 'Remove conversation',
    });
    const chatHistoryTooltip = t({
        comment: 'Document assistant actions - Conversation history button tooltip label',
        message: 'Conversation history',
    });
    const toggleExpandTooltip = expanded
        ? t({
            comment: 'Document assistant actions - Collapse window button tooltip label',
            message: 'Collapse window',
        })
        : t({
            comment: 'Document assistant actions - Expand window button tooltip label',
            message: 'Expand window',
        });
    const buttonProps = {
        $round: true,
        size: 'small',
        appearance: 'ghost',
    };
    const showDeleteDialog = () => setRenderDeleteDialog(true);
    const hideDeleteDialog = () => setRenderDeleteDialog(false);
    const deleteConversation = () => __awaiter(this, void 0, void 0, function* () {
        if (!activeChat) {
            return;
        }
        setDeleting(true);
        try {
            yield deleteChat(activeChat.id);
            hideDeleteDialog();
            addToast({
                appearance: 'success',
                title: t({
                    comment: 'Document assistant - success when removing a conversation',
                    message: 'Conversation removed',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'We could not remove the conversation. Try again',
                    comment: 'Document assistant - error when trying to remove a conversation',
                }),
            });
        }
        setDeleting(false);
    });
    if (initiating || initiatingError) {
        return null;
    }
    return (<Box alignItems="center" gap="sm">
      {showDeleteChat && (<IconButton {...buttonProps} disabled={disableDeleteChat} aria-label={deleteChatTooltip} onClick={showDeleteDialog}>
          <Falcon icon="trash"/>
        </IconButton>)}
      {showChatHistory && (<IconButton {...buttonProps} aria-label={chatHistoryTooltip} onClick={goToConversations}>
          <Falcon icon="comments"/>
        </IconButton>)}
      {!isMobile && (<IconButton {...buttonProps} aria-label={toggleExpandTooltip} onClick={toggleExpanded}>
          <Falcon icon="expand-wide"/>
        </IconButton>)}
      {renderDeleteDialog && (<RemoveConversationModal loading={deletingChat} onCancel={hideDeleteDialog} onConfirm={deleteConversation}/>)}
    </Box>);
}
