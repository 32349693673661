var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { nullToUndefined } from 'app/domains/esigning/utils';
import { useActiveDocument } from 'shared/domains/documents';
import { GetLastSignRequestDocument } from './GetLastSignRequest.gql';
import { mapToRecipient, mapToSignatory } from './converter';
export const useLastSignRequest = () => {
    const { id: documentId } = useActiveDocument();
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { canUseEsigningMessages } = providerProperties;
    const _a = useQuery(GetLastSignRequestDocument, { variables: { documentId } }), { data } = _a, rest = __rest(_a, ["data"]);
    const { fileSystemDocument } = data || {};
    const { lastSignRequest } = fileSystemDocument || {};
    const { message: initialMessage, signatories: initialSignatories = [], ccRecipients: initialCcRecipients = [], } = lastSignRequest || {};
    const signatories = initialSignatories.map(mapToSignatory);
    const ccRecipients = (initialCcRecipients === null || initialCcRecipients === void 0 ? void 0 : initialCcRecipients.map(mapToRecipient)) || [];
    const message = canUseEsigningMessages ? nullToUndefined(initialMessage) : undefined;
    return Object.assign({ lastSignRequest: Object.assign(Object.assign({}, lastSignRequest), { signatories, ccRecipients, message }) }, rest);
};
