import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDocumentLocked } from 'app/domains/documents';
import { getTooltipFromReason } from './utils';
export function LockedChip() {
    const { reason } = useDocumentLocked();
    const tooltip = getTooltipFromReason(reason);
    if (!tooltip) {
        return null;
    }
    return (<Tooltip side="bottom" title={tooltip}>
      <Chip appearance="warning" icon={<Falcon icon="lock"/>} label={t({
            comment: 'Document locked chip - Chip label',
            message: 'Document locked',
        })}/>
    </Tooltip>);
}
