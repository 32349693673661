import { t } from '@lingui/macro';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AssistantContext, useAssistant } from 'app/domains/assistant';
import { useGtm } from 'shared/domains/tracking';
import { AlignRight, Icon, StyledListItem } from './ListButton/styled';
export function ChatButton() {
    const history = useHistory();
    const { url } = useRouteMatch();
    const gtm = useGtm();
    const { openAssistantDrawer } = useAssistant();
    const handleClick = () => {
        gtm.push({ event: 'cta_document_assistant_sidebar' });
        history.push(url);
        openAssistantDrawer(AssistantContext.Document);
    };
    return (<StyledListItem onClick={handleClick}>
      <AvatarWithLabel appearance="ai" solid shape="circle" size="lg" title={t({
            message: 'AI assistant',
            comment: 'Document settings drawer - chat button title',
        })} subtitle={t({
            message: 'Ask, draft and review',
            comment: 'Document settings drawer - chat button subtitle',
        })}>
        <Falcon icon="sparkles"/>
      </AvatarWithLabel>
      <AlignRight>
        <Icon>
          <Falcon icon="chevron-right"/>
        </Icon>
      </AlignRight>
    </StyledListItem>);
}
