import { t } from '@lingui/macro';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
import { allowedPropertiesTranslations } from './translations';
const values = [
    'createdAt',
    'metadataDocumentDateSigned',
    'metadataDocumentNoticeDate',
    'metadataDocumentProbationaryPeriodExpirationDate',
    'metadataDocumentStartDate',
    'metadataDocumentTerminationDate',
    'updatedAt',
];
export const getDateHistogramAllowedProperties = () => {
    const translatedValues = values.map(value => ({
        value,
        label: allowedPropertiesTranslations(value),
    }));
    return {
        type: SearchAggregationType.DateHistogram,
        label: t({
            message: 'Date histogram',
            comment: 'Insights - Date histogram aggregation',
        }),
        values: translatedValues,
    };
};
