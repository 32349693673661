import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Chat } from './Chat';
import { Dashboard } from './Dashboard';
import { History } from './History';
import { AssistantViewType } from '../../types';
import { useAssistant } from '../AssistantProvider';
export function AssistantChat() {
    const { view, activeChat, initiatingError } = useAssistant();
    if (initiatingError) {
        return (<EmptyState withAvatar icon={<Falcon icon="sparkles"/>} title={t({
                comment: 'Assistant chat error title',
                message: 'Could not load the Assistant',
            })} description={t({
                comment: 'Assistant chat error description',
                message: 'Something went wrong while trying to load the Assistant',
            })}/>);
    }
    if (view === AssistantViewType.Chat && activeChat) {
        return <Chat chat={activeChat}/>;
    }
    if (view === AssistantViewType.Conversations) {
        return <History />;
    }
    return <Dashboard />;
}
