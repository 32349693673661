import { forwardRef } from 'react';
import { Box, Typography } from '@pocketlaw/tetris';
import { PopButtonContainer, PopButtonContent } from './styled';
export const PopButton = forwardRef((props, ref) => {
    const { icon, label, disabled, onClick } = props;
    return (<PopButtonContainer disabled={disabled} onClick={onClick} ref={ref}>
      <PopButtonContent>
        <Box flexDirection="column" gap="lg">
          {icon}
          <Typography $fontSize="small" $fontWeight="semibold">
            {label}
          </Typography>
        </Box>
      </PopButtonContent>
    </PopButtonContainer>);
});
