import { useMemo, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { TextInput, Button, Dialog, DialogHeader, DialogContent, DialogFooter, Grid, Column, Loader, Alert, AvatarWithLabel, useToast, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { useRenameEntry } from 'app/domains/filesystem';
const Schema = object().shape({
    name: string().trim().required(),
});
export function RenameEntryDialog(props) {
    const { onRenamed, type, payload, onClose } = props;
    const [renameEntry, mutation] = useRenameEntry({ type, id: payload.id, onRenamed });
    const initialValues = useMemo(() => ({ name: payload.name }), [payload]);
    const { addToast } = useToast();
    const handleSubmit = useCallback((values, helpers) => {
        const { resetForm } = helpers;
        renameEntry(values.name)
            .then(onClose)
            .catch(() => {
            addToast({
                title: t({
                    message: 'Something went wrong, please try again later.',
                    comment: 'Rename document/folder dialog error toast',
                }),
                appearance: 'danger',
            });
            resetForm({ values: initialValues });
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]);
    const title = t({
        message: 'Edit name',
        comment: 'rename document/folder dialog title',
    });
    return (<Formik initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit} validateOnMount>
      {({ isSubmitting, isValid }) => (<Dialog as={Form} size="sm" onClose={isSubmitting ? undefined : onClose}>
          <DialogHeader media={<AvatarWithLabel size="xl" shape="square" title={title}>
                <Falcon icon="pen"/>
              </AvatarWithLabel>}/>
          <DialogContent>
            <Grid directions="vertical" spacing={2}>
              <Column width="100%">
                <Field name="name" as={TextInput} stretch/>
              </Column>
              {mutation.error ? (<Column width="100%">
                  <Alert appearance="danger" message={t({
                    message: 'Something went wrong, please try again later',
                    comment: '',
                })}/>
                </Column>) : null}
            </Grid>
          </DialogContent>
          <DialogFooter>
            <Button type="button" appearance="secondary" disabled={isSubmitting} onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button type="submit" disabled={!isValid || isSubmitting}>
              <Trans>Rename</Trans>
              {isSubmitting && <Loader />}
            </Button>
          </DialogFooter>
        </Dialog>)}
    </Formik>);
}
