import { themeGet, breakpoints, scrollbar, pxToRem } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const AssistantChatContainer = styled.div `
  --assistant-chat-padding: ${themeGet('spacing.2xl')};

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: ${themeGet('spacing.2xl')} var(--assistant-chat-padding);
  padding-top: 0;

  ${breakpoints.xl} {
    --assistant-chat-padding: ${themeGet('spacing.3xl')};
  }

  ${breakpoints.xxl} {
    --assistant-chat-padding: ${themeGet('spacing.4xl')};
  }

  ${props => props.$withScroll &&
    css `
      overflow-y: auto;
      margin-right: ${themeGet('spacing.md')};
      padding-right: calc(
        var(--assistant-chat-padding) - ${pxToRem(6)} - ${themeGet('spacing.md')}
      );
      ${scrollbar({ trackMargin: 'md' })};
    `}
`;
