import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useEsigning } from 'app/domains/esigning';
export function EsigningChip() {
    const { pending, signed } = useEsigning();
    const icon = <Falcon icon="pen-swirl"/>;
    const position = 'bottom';
    if (pending) {
        return (<Tooltip side={position} title={t({
                comment: 'Document sent for e-signing - chip tooltip',
                message: 'Sent for eSign (Can not be modified)',
            })}>
        <Chip icon={icon} appearance="secondary" label={t({
                comment: 'Document sent for e-signing - chip label',
                message: 'Sent for eSign',
            })}/>
      </Tooltip>);
    }
    if (signed) {
        return (<Tooltip side={position} title={t({
                comment: 'Document e-signing signed by all parties - chip tooltip',
                message: 'Contract signed by all parties',
            })}>
        <Chip icon={icon} appearance="success" label={t({
                comment: 'Document e-signing signed by all parties - chip label',
                message: 'Contract signed',
            })}/>
      </Tooltip>);
    }
    return null;
}
