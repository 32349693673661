import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Menu as TetrisMenu, MenuList, IconButton, MenuItem, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { useRole } from 'app/domains/users/hooks/useRole';
export function Menu(props) {
    const { disabled = false, id, onRemove } = props;
    const [open, setOpen] = useState(false);
    const currentUserRole = useRole();
    const handleRemove = () => {
        setOpen(false);
        return onRemove();
    };
    return (<TetrisMenu open={open} onClose={() => setOpen(false)} target={<IconButton appearance="ghost" aria-label={t `More options`} disabled={disabled} onClick={() => setOpen(!open)}>
          <Falcon icon="ellipsis-h" $size={16} $color="gray.700"/>
        </IconButton>}>
      <MenuBody>
        <MenuList>
          <MenuItem as={Link} to={`/users/${id}`}>
            <Trans>Edit</Trans>
          </MenuItem>
          {currentUserRole.has('owner') && (<MenuItem onClick={handleRemove}>
              <Trans>Remove</Trans>
            </MenuItem>)}
        </MenuList>
      </MenuBody>
    </TetrisMenu>);
}
