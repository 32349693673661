import { t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useActiveDocument, useIsShared } from 'shared/domains/documents';
export function SharedExternallyChip() {
    const { id: documentId } = useActiveDocument();
    const shared = useIsShared(documentId);
    if (!shared) {
        return null;
    }
    return (<Tooltip side="bottom" title={t({
            comment: 'Document shared externally - chip tooltip',
            message: 'This document is being shared with external users. Any comments or changes to this document will be visible to those external users.',
        })}>
      <Chip appearance="secondary" icon={<Falcon icon="share"/>} label={t({
            comment: 'Document shared externally - chip label',
            message: 'Shared externally',
        })}/>
    </Tooltip>);
}
