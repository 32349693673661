import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PlaybookReferenceDialog } from 'app/domains/review/components/PlaybookReferenceDialog';
export function PlaybookReference(props) {
    const { feedback, round = false } = props;
    const [showDialog, setShowDialog] = useState(false);
    const openDialog = () => setShowDialog(true);
    const closeDialog = () => setShowDialog(false);
    return (<Fragment>
      {showDialog && <PlaybookReferenceDialog feedback={feedback} onClose={closeDialog}/>}
      <Tooltip side="top" title={t({
            message: 'See playbook reference',
            comment: 'Plai Review - Playbook reference button - button tooltip',
        })}>
        <IconButton $round={round} size="small" appearance="ghost" onClick={openDialog}>
          <Falcon icon="book"/>
        </IconButton>
      </Tooltip>
    </Fragment>);
}
