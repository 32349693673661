import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useDocumentApprovers } from 'app/domains/esigning/hooks/useDocumentApprovers';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { ApprovalRequestStatus } from 'shared/domains/apollo/generated/types';
export const useDocumentApproversWithStatus = () => {
    const { documentId } = useEsigning();
    const { documentApprovers, loading: loadingApprovers } = useDocumentApprovers(documentId);
    const { lastApprovalRequest, loading: loadingApproval } = useLastApprovalRequest(documentId);
    const loading = loadingApprovers || loadingApproval;
    const approversMap = documentApprovers.reduce((acc, approver) => {
        acc[approver.userId] = {
            userId: approver.userId,
            name: approver.name,
            email: approver.email,
            status: null,
        };
        return acc;
    }, {});
    if (lastApprovalRequest && lastApprovalRequest.status === ApprovalRequestStatus.Pending) {
        lastApprovalRequest.approvers.forEach(approver => {
            var _a;
            if ((_a = approver === null || approver === void 0 ? void 0 : approver.user) === null || _a === void 0 ? void 0 : _a.id) {
                const currentApprover = approversMap[approver.user.id];
                if (currentApprover) {
                    approversMap[approver.user.id] = Object.assign(Object.assign({}, currentApprover), { status: approver.status || null });
                }
            }
        });
    }
    const approvers = Object.values(approversMap);
    const filteredApprovers = approvers.filter(approver => {
        if ((lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) === ApprovalRequestStatus.Pending) {
            return approver.status !== null;
        }
        return true;
    });
    return { approvers: filteredApprovers, loading };
};
