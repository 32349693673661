export const EXTRACT_KEY_INFORMATION_PROMPT = `Please review the contract and give me a short breakdown of the following terms. Please present in a table with two columns (Aspect (in Bold) and Term (not bolded)).

- The term (length) of the agreement.

- Termination clauses, answer in bullet points (below each other) for different types of termination. Answer in at most 100 words. Also include the notice period.

- Auto-renewal.

- Fees and costs involved in the contract. Please pull out all information where there are fees related and the amounts related to them.

- Warranties and liability. Important to mention in the liability is uncapped if that is the case.

- Indemnities.Flag any indemnity clause. Answer in at most 100 words.

- Governing law and Jurisdiction.
`;
export const OVERVIEW_OF_IMPORTANT_DATES_PROMPT = `Summarize key dates in a table.

Focus specifically on upcoming events, planned dates, and forecasted times.

Identify dates expressed in various ways:

- as specific dates

- years

- months

- or in relation to other events

Present a comprehensive overview table showing the event and corresponding date/period.

Limit the summary to a maximum of 150 words.`;
export const PROOFREAD_CONTRACT_PROMPT = `Review this contract for:

- Spelling and grammatical errors

- Structural inconsistencies

- Vague or ambiguous terminology

- Undefined or imprecisely defined terms

- Present in a table with clear references to where in the contract this can be found
`;
