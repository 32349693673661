var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { CreateDocumentChatDocument } from './mutation.gql';
export const useCreateChat = () => {
    const { id: documentId } = useActiveDocument();
    const [mutate, { loading }] = useMutation(CreateDocumentChatDocument);
    const createChat = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield mutate({
            variables: { documentId },
            update: (cache, incoming) => {
                cache.modify({
                    fields: {
                        documentChatsByUser(existing = [], { toReference }) {
                            var _a;
                            if (!((_a = incoming.data) === null || _a === void 0 ? void 0 : _a.createDocumentChat)) {
                                return existing;
                            }
                            const ref = toReference(incoming.data.createDocumentChat, true);
                            return [ref, ...existing];
                        },
                    },
                });
            },
        });
        return data === null || data === void 0 ? void 0 : data.createDocumentChat.id;
    });
    return { createChat, loading };
};
