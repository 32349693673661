import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AssistantContext } from '../../types';
import { useAssistant } from '../AssistantProvider';
export const useInputElements = () => {
    const { changeContext, documentSelection } = useAssistant();
    if (!(documentSelection === null || documentSelection === void 0 ? void 0 : documentSelection.value)) {
        return { elements: undefined };
    }
    const selectionElement = {
        onClick: () => changeContext(AssistantContext.Document),
        icon: <Falcon icon="file-circle-info"/>,
        label: t({
            comment: 'Assistant chat dashboard - Working with a document selection label',
            message: 'Working with a document selection',
        }),
    };
    return { elements: [selectionElement] };
};
