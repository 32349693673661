var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { GetPendingApprovalsQueryDocument } from './GetPendingApprovalsQuery.gql';
export const usePendingApprovalContracts = () => {
    const _a = useQuery(GetPendingApprovalsQueryDocument), { data } = _a, rest = __rest(_a, ["data"]);
    const approvalRequests = (data === null || data === void 0 ? void 0 : data.esigningApprovalRequests) || [];
    const pendingApprovalContracts = approvalRequests.map(approvalRequest => {
        var _a, _b, _c;
        return ({
            id: ((_a = approvalRequest.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.id) || '',
            name: ((_b = approvalRequest.fileSystemDocument) === null || _b === void 0 ? void 0 : _b.name) || '',
            requester: ((_c = approvalRequest.requestedByUser) === null || _c === void 0 ? void 0 : _c.name) || '',
            requestedAt: approvalRequest.createdAt || '',
            approvers: approvalRequest.approvers.map(approver => {
                var _a, _b;
                return ({
                    id: ((_a = approver === null || approver === void 0 ? void 0 : approver.user) === null || _a === void 0 ? void 0 : _a.id) || '',
                    name: ((_b = approver === null || approver === void 0 ? void 0 : approver.user) === null || _b === void 0 ? void 0 : _b.name) || '',
                });
            }) || [],
        });
    });
    return Object.assign(Object.assign({}, rest), { pendingApprovalContracts });
};
