import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function AlertHandler(props) {
    const { error } = props;
    if (error === 'NO_FILE_PROVIDED') {
        return (<Alert appearance="warning" message={t({
                comment: 'Error message when no file was provided when uploading a file',
                message: 'No file was provided. Please, try again.',
            })}/>);
    }
    if (error === 'FILE_SIZE_EXCEEDS_MAX') {
        return (<Alert appearance="warning" message={t({
                comment: 'Error message when the size limit has been exceeded when uploading a file',
                message: 'The file you selected is too large (max 10 MB).',
            })}/>);
    }
    if (error === 'UNSUPPORTED_FILE_FORMAT') {
        return (<Alert appearance="warning" message={t({
                comment: 'Error message when the file format is not supported when uploading a file',
                message: 'The file you tried to upload is not supported. Please try again with another file.',
            })}/>);
    }
}
