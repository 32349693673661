import { Fragment } from 'react';
import { Box, IconButton, Loader, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Content, LoaderContainer, StyledDrawer } from './styled';
export function TemplateDrawer(props) {
    const { header, subheader, children, loading, onClose } = props;
    return (<StyledDrawer open position="right">
      {loading ? (<LoaderContainer>
          <Loader size={8}/>
        </LoaderContainer>) : (<Fragment>
          <Box flexDirection="column" p="2xl" pb="0" style={{ flexShrink: 0 }}>
            <Box alignItems="center" justifyContent="space-between">
              <Typography $fontSize="huge" $appearance="300">
                {header}
              </Typography>
              <IconButton $round size="small" appearance="ghost" onClick={onClose}>
                <Falcon icon="xmark"/>
              </IconButton>
            </Box>
            <Typography $fontSize="small" $appearance="100">
              {subheader}
            </Typography>
          </Box>
          <Content>{children}</Content>
        </Fragment>)}
    </StyledDrawer>);
}
