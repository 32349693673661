var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Function to validate editor bundle version with current bundle version
 * Will return true if versions match, false if not.
 * @param signal
 * @returns
 */
export function validateEditorBundleVersion(signal) {
    return __awaiter(this, void 0, void 0, function* () {
        const activeVersion = process.env.PLEDITOR_BUNDLE_VERSION;
        const response = yield fetch('/version.json', {
            method: 'GET',
            signal,
            headers: new Headers([['Accept', 'application/json']]),
        });
        const contentType = response.headers.get('content-type');
        if (!(contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) {
            throw new Error(`Response of "/version.json" expected type application/json, but was ${contentType}`);
        }
        const { pleditor } = (yield response.json());
        return pleditor.version === activeVersion;
    });
}
