var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { GetFileSystemDocumentApproversDocument } from './GetFileSystemDocumentApprovers.gql';
function dedupe(items) {
    const map = new Map();
    const sortedItems = items.toSorted((a, b) => a.approvalOrder - b.approvalOrder);
    sortedItems.forEach(item => {
        if (!map.has(item.userId)) {
            map.set(item.userId, item);
        }
    });
    return Array.from(map.values());
}
export const useDocumentApprovers = (documentId) => {
    var _a, _b, _c, _d;
    const _e = useQuery(GetFileSystemDocumentApproversDocument, {
        variables: { documentId },
    }), { data } = _e, rest = __rest(_e, ["data"]);
    const approvers = ((_b = (_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.approval) === null || _b === void 0 ? void 0 : _b.approvers) || [];
    const teamApprovers = ((_d = (_c = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _c === void 0 ? void 0 : _c.approval) === null || _d === void 0 ? void 0 : _d.teamApprovers) || [];
    const documentApprovers = [
        ...teamApprovers.flatMap(teamApprover => {
            var _a, _b;
            const users = ((_b = (_a = teamApprover === null || teamApprover === void 0 ? void 0 : teamApprover.team) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.map(member => member.user)) || [];
            return users.map(user => ({
                approvalOrder: teamApprover.approvalOrder || 0,
                userId: (user === null || user === void 0 ? void 0 : user.id) || '',
                name: (user === null || user === void 0 ? void 0 : user.name) || '',
                email: (user === null || user === void 0 ? void 0 : user.email) || '',
            }));
        }),
        ...approvers.map(approver => {
            var _a, _b, _c;
            return ({
                approvalOrder: approver.approvalOrder || 0,
                userId: ((_a = approver.user) === null || _a === void 0 ? void 0 : _a.id) || '',
                name: ((_b = approver.user) === null || _b === void 0 ? void 0 : _b.name) || '',
                email: ((_c = approver.user) === null || _c === void 0 ? void 0 : _c.email) || '',
            });
        }),
    ];
    return Object.assign(Object.assign({}, rest), { documentApprovers: dedupe(documentApprovers) });
};
