import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { CcsDocumentFlushReason } from 'shared/domains/apollo/generated/types';
import { documentFlush } from 'shared/domains/documents';
export function FlushReasonModalExternal() {
    const { addToast } = useToast();
    const flush = useReactiveVar(documentFlush);
    const history = useHistory();
    useEffect(() => {
        function handleNewBundleVersion() {
            addToast({
                appearance: 'info',
                title: t({
                    comment: 'RTC Document flushed in shared app - Reason: New Bundle Version - Toast title',
                    message: 'Document updated',
                }),
                message: t({
                    comment: 'RTC Document flushed in shared app - Reason: New Bundle Version - Toast message',
                    message: 'The document has been changed',
                }),
            });
            window.location.reload();
            documentFlush({ state: 'not-initialized' });
        }
        function handleDocumentDeleted() {
            addToast({
                appearance: 'info',
                title: t({
                    comment: 'RTC Document flushed in shared app - Reason: Deleted - Toast title',
                    message: 'Document deleted',
                }),
                message: t({
                    comment: 'RTC Document flushed in shared app - Reason: Deleted - Toast message',
                    message: 'The document has been deleted deleted',
                }),
            });
            history.push('/');
            documentFlush({ state: 'not-initialized' });
        }
        function handleDefault() {
            addToast({
                appearance: 'info',
                title: t({
                    comment: 'RTC Document flushed in shared app - Reason: Changed - Toast title',
                    message: 'Document changed',
                }),
                message: t({
                    comment: 'RTC Document flushed in shared app - Reason: Changed - Toast message',
                    message: 'The document has been changed',
                }),
            });
            history.push('/');
            documentFlush({ state: 'not-initialized' });
        }
        if (flush.state === 'done') {
            switch (flush.reason) {
                case CcsDocumentFlushReason.NewBundleVersion:
                    handleNewBundleVersion();
                    break;
                case CcsDocumentFlushReason.Deleted:
                    handleDocumentDeleted();
                    break;
                default:
                    handleDefault();
                    break;
            }
        }
    }, [addToast, flush, history]);
    return null;
}
