import { Trans } from '@lingui/macro';
import { Title, Subtitle, StyledLink, YouText, InfoContainer } from './styled';
export function Info(props) {
    const { title, subtitle, appearance = 'default', to = undefined, isSelf = false } = props;
    const titleMessage = isSelf ? (<Trans comment="The Title displays the name of user; This translation indicates to the user it is them">
      {title} <YouText>(You)</YouText>
    </Trans>) : (title);
    return (<InfoContainer>
      {to ? <StyledLink to={to}>{titleMessage}</StyledLink> : <Title>{titleMessage}</Title>}
      {subtitle && <Subtitle $appearance={appearance}>{subtitle}</Subtitle>}
    </InfoContainer>);
}
