import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { Approvers } from './Approvers';
import { SectionContent, SectionFooter } from '../../components';
import { NoApproversAlert } from '../NoApproversAlert';
import { RejectedByApprover } from '../RejectedByApprover';
export function OneOfState(props) {
    const { onSubmit } = props;
    const { approvers } = useDocumentApproversWithStatus();
    const noAvailableApprovers = approvers.length === 0;
    const [selectedApproverUserIds, setSelectedApproverUserIds] = useState([]);
    const disableSubmit = selectedApproverUserIds.length === 0;
    const handleToggleApprover = (id) => {
        if (selectedApproverUserIds.includes(id)) {
            setSelectedApproverUserIds(selectedApproverUserIds.filter(approverId => approverId !== id));
        }
        else {
            setSelectedApproverUserIds([...selectedApproverUserIds, id]);
        }
    };
    const handleSubmit = () => onSubmit(selectedApproverUserIds);
    const alertTitle = t({
        comment: 'Document requires approval state - Needs approval by one of the approvers - Info label',
        message: 'Select approvers',
    });
    const alertMessage = t({
        comment: 'Document requires approval state - Needs approval by one of the approvers - Info message',
        message: 'Select who to send the approval request to. When one of the approvers have approved, the document can be sent for eSigning.',
    });
    return (<Fragment>
      <SectionContent>
        <RejectedByApprover />
        <Approvers selectedApproverUserIds={selectedApproverUserIds} onToggleApprover={handleToggleApprover}/>
      </SectionContent>
      <SectionFooter>
        {noAvailableApprovers ? (<NoApproversAlert />) : (<Alert appearance="info" title={alertTitle} message={alertMessage}/>)}
        <Button $stretch disabled={disableSubmit} onClick={handleSubmit}>
          <Trans comment="Ask for approval button label">Ask for approval</Trans>
        </Button>
      </SectionFooter>
    </Fragment>);
}
