import { Box, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, IconContainer } from './styled';
export function PromptButton(props) {
    const { label, disabled, onClick } = props;
    return (<Container disabled={disabled} onClick={onClick}>
      <Box alignItems="center" justifyContent="space-between">
        <Typography $fontSize="small" $fontWeight="semibold" $appearance="300">
          {label}
        </Typography>
        <IconContainer>
          <Falcon icon="arrow-right"/>
        </IconContainer>
      </Box>
    </Container>);
}
