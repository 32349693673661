import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { IconButton, Menu, MenuItem, MenuList, MenuDivider, Tooltip, MenuBody, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { useEsigningSignatoriesFormField, } from 'app/domains/esigning/components/EsigningForm';
import { ConfirmRemoveDialog } from './ConfirmRemoveDialog';
export function PartyActions(props) {
    const { signatory } = props;
    const { isDefault = false } = signatory;
    const { signatories, editSignatory, removeSignatory } = useEsigningSignatoriesFormField();
    const [renderActions, setRenderActions] = useState(false);
    const [renderEditDialog, setRenderEditDialog] = useState(false);
    const [renderRemoveDialog, setRenderRemoveDialog] = useState(false);
    const showActions = () => setRenderActions(true);
    const hideActions = () => setRenderActions(false);
    const hideRemoveDialog = () => setRenderRemoveDialog(false);
    const showEditDialog = () => {
        hideActions();
        setRenderEditDialog(true);
    };
    const showRemoveDialog = () => {
        hideActions();
        setRenderRemoveDialog(true);
    };
    const handleConfirmRemove = () => {
        setRenderRemoveDialog(false);
        removeSignatory(signatory);
    };
    const handleHideEditModal = () => setRenderEditDialog(false);
    const handleEditSignatory = (editedSignatory) => {
        editSignatory(editedSignatory);
    };
    if (isDefault) {
        return (<Tooltip side="left" title={t({
                comment: 'Tooltip for default signatory (signing and non signing party)',
                message: 'Default party',
            })}>
        <IconButton $round size="small" appearance="ghost">
          <Falcon icon="lock"/>
        </IconButton>
      </Tooltip>);
    }
    return (<Fragment>
      {renderEditDialog && (<ESignSignatoryFormDialog type="Signatory" initialValues={signatory} allSignatories={signatories} onClose={handleHideEditModal} onSubmit={handleEditSignatory}/>)}
      {renderRemoveDialog && (<ConfirmRemoveDialog onCancel={hideRemoveDialog} onConfirm={handleConfirmRemove}/>)}
      <Menu open={renderActions} onClose={hideActions} target={<IconButton $round size="small" appearance="ghost" onClick={showActions}>
            <Falcon icon="ellipsis-v"/>
          </IconButton>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={showEditDialog}>
              <Falcon icon="pen"/>
              <Trans comment="Label for editing signing contact">Edit</Trans>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={showRemoveDialog}>
              <Falcon icon="trash-can"/>
              <Trans comment="Label for removing signing contact">Remove</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
    </Fragment>);
}
