/* eslint-disable lingui/no-single-tag-to-translate */
/* eslint-disable lingui/no-single-variables-to-translate */
import { Trans } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { GradientText, IconContainer } from './styled';
export function WelcomeText() {
    return (<Box flexDirection="column" gap="md">
      <IconContainer>
        <Falcon icon="sparkles"/>
      </IconContainer>
      <GradientText>
        <Trans comment="Plai assistant chat dashboard - welcome text">
          How can I
          <br />
          assist with your
          <br />
          document today?
        </Trans>
      </GradientText>
    </Box>);
}
