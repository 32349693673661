var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t } from '@lingui/macro';
import { AvatarWithLabel, Box, IconButton, Loader, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { useAssistant } from '../../../AssistantProvider';
import { RemoveConversationModal } from '../../../RemoveConversationModal';
import { getPromptByType } from '../../getPromptByType';
import { Container } from './styled';
export function Item(props) {
    const { chat, dateFormat } = props;
    const { messages } = chat;
    const { goToChat, deleteChat } = useAssistant();
    const { addToast } = useToast();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);
    if (!messages.length) {
        return null;
    }
    const firstMessage = messages[0];
    const lastMessage = messages[messages.length - 1];
    const { type, content } = firstMessage;
    const { createdAt } = lastMessage;
    const prompt = getPromptByType(type);
    const text = prompt || content;
    const hideDeleteDialog = () => setShowDeleteDialog(false);
    const deleteConversation = () => __awaiter(this, void 0, void 0, function* () {
        setDeleting(true);
        try {
            yield deleteChat(chat.id);
            hideDeleteDialog();
            addToast({
                appearance: 'success',
                title: t({
                    comment: 'Document assistant - success when removing a conversation',
                    message: 'Conversation removed',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'We could not remove the conversation. Try again',
                    comment: 'Document assistant - error when trying to remove a conversation',
                }),
            });
        }
        setDeleting(false);
    });
    const handleGoTo = () => goToChat(chat.id);
    const handleDelete = () => setShowDeleteDialog(true);
    return (<Container>
      <AvatarWithLabel solid truncate size="sm" appearance="light" title={text !== null && text !== void 0 ? text : undefined} subtitle={createdAt ? localizedFormatDate(createdAt, dateFormat) : undefined}>
        <Falcon icon="comment"/>
      </AvatarWithLabel>
      <Box alignItems="center" gap="md">
        <IconButton size="xs" appearance="ghost" disabled={deleting} onClick={handleDelete} aria-label={t({
            comment: 'Document assistant - conversation history - remove conversation tooltip',
            message: 'Remove conversation',
        })}>
          {deleting ? <Loader /> : <Falcon icon="trash"/>}
        </IconButton>
        <IconButton size="xs" appearance="secondary" disabled={deleting} onClick={handleGoTo} aria-label={t({
            comment: 'Document assistant - conversation history - enter conversation tooltip',
            message: 'Enter conversation',
        })}>
          <Falcon icon="arrow-right"/>
        </IconButton>
      </Box>
      {showDeleteDialog && (<RemoveConversationModal loading={deleting} onCancel={hideDeleteDialog} onConfirm={deleteConversation}/>)}
    </Container>);
}
