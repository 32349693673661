import { useQuery } from '@apollo/client';
import { userAccessQuery } from './query';
export function useUserAccess(entryId) {
    return useQuery(userAccessQuery, {
        variables: {
            id: entryId || '',
        },
        skip: !entryId,
        returnPartialData: false,
    });
}
