import { t } from '@lingui/macro';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
export function getAuthenticationModeTitle(slug) {
    switch (slug) {
        case EsigningProviderAuthenticationModeSlug.SmsOtp:
            return t({ message: 'SMS Verification', comment: 'E-signing SMS OTP authentication label' });
        case EsigningProviderAuthenticationModeSlug.NorBankid:
            return t({
                message: 'Norwegian BankID',
                comment: 'E-signing Norwegian BankID authentication label',
            });
        case EsigningProviderAuthenticationModeSlug.SweBankid:
            return t({
                message: 'Swedish BankID',
                comment: 'E-signing Swedish BankID authentication label',
            });
        case EsigningProviderAuthenticationModeSlug.Email:
            return t({
                message: 'Simple Signing',
                comment: 'E-signing Simple Signing authentication label',
            });
        case EsigningProviderAuthenticationModeSlug.SmartId:
            return t({
                message: 'Smart-ID',
                comment: 'E-signing Smart-ID authentication label',
            });
        case EsigningProviderAuthenticationModeSlug.FinBankid:
            return t({
                message: 'Finnish BankID',
                comment: 'E-signing Finnish BankID authentication label',
            });
        case EsigningProviderAuthenticationModeSlug.Mitid:
            return t({
                message: 'MitID',
                comment: 'E-signing MitID authentication label',
            });
        default:
            return slug;
    }
}
