import { Trans, t } from '@lingui/macro';
import { FilterButton, MenuBody } from '@pocketlaw/tetris';
import { SearchableMenu, SearchableMenuMultiSelectList } from 'shared/domains/common-ui';
import { useFilter } from '../useFilter';
export function MultiselectOperator(props) {
    var _a, _b;
    const { items, name } = props;
    const filter = useFilter(name);
    const selectedIds = (((_a = filter.value) === null || _a === void 0 ? void 0 : _a[filter.operator]) || []);
    const [firstSelection] = selectedIds || [];
    const secondaryCount = selectedIds.length > 1 ? `+${selectedIds.length - 1}` : '';
    const selectedTitle = (_b = items.find(item => item.id === firstSelection)) === null || _b === void 0 ? void 0 : _b.title;
    const secondaryText = selectedIds.length ? `${selectedTitle} ${secondaryCount}` : '';
    const handleOnSelect = (item) => {
        const { id } = item;
        const selected = selectedIds.includes(id);
        if (!selected) {
            const newData = { [filter.operator]: [...selectedIds, id] };
            filter.setValue(newData);
            return;
        }
        if (selectedIds.length === 1) {
            filter.resetValue();
        }
        else {
            const newData = {
                [filter.operator]: selectedIds.filter(selectedItem => selectedItem !== id),
            };
            filter.setValue(newData);
        }
    };
    return (<SearchableMenu items={items} keys={['title']} placeholder={t({
            message: 'Search',
            comment: 'Multi select filter search input placeholder',
        })} initiallyOpen={!filter.hasValue} renderTarget={({ setOpen, open }) => (<FilterButton type="button" onClick={() => setOpen(!open)} appearance={filter.isTouched ? 'draft' : 'default'}>
          {secondaryText || <Trans comment="Multi select filter button">Select value</Trans>}
        </FilterButton>)}>
      <MenuBody>
        <SearchableMenuMultiSelectList selected={selectedIds} renderItem={({ title }) => title} onSelect={handleOnSelect}/>
      </MenuBody>
    </SearchableMenu>);
}
