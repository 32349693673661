import { Typography } from '@pocketlaw/tetris';
import { Item, TitleContainer, ActionsContainer } from './styled';
export function SidebarListItem(props) {
    const { title, action, children } = props;
    return (<Item>
      <TitleContainer>
        <Typography data-testid="metadata-name" $fontWeight="bold" $fontSize="small" $lineHeight="neutralized">
          {title}
        </Typography>
        {action && <ActionsContainer>{action}</ActionsContainer>}
      </TitleContainer>

      {children}
    </Item>);
}
