import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
export function RejectedByApprover() {
    var _a;
    const { documentId } = useEsigning();
    const { rejecter } = useLastApprovalRequest(documentId);
    if (!rejecter) {
        return null;
    }
    const alertTitle = t({
        comment: 'Document approval rejected alert - Title',
        message: 'Approval rejected',
    });
    const alertMessage = t({
        comment: 'Document approval rejected alert - Message',
        message: `${(_a = rejecter.user) === null || _a === void 0 ? void 0 : _a.name} rejected the approval request. Review your document and send it for approval again.`,
    });
    return <Alert appearance="warning" title={alertTitle} message={alertMessage}/>;
}
