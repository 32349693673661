var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Link, useRouteMatch } from 'react-router-dom';
import { NavItemContainer, NavItemText } from './styled';
export default function NavItem(props) {
    const { icon, children, to, matchPath, exact = true } = props, rest = __rest(props, ["icon", "children", "to", "matchPath", "exact"]);
    const match = useRouteMatch({
        path: matchPath || to,
        exact,
    });
    const active = !!match;
    return (<NavItemContainer as={Link} $active={active} to={to} {...rest}>
      {icon}
      <NavItemText>{children}</NavItemText>
    </NavItemContainer>);
}
