import { useCallback } from 'react';
export function useArrowNavigation() {
    const keyDownHandler = useCallback(event => {
        var _a, _b, _c, _d;
        if (event.key === 'ArrowDown') {
            (_b = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.nextElementSibling) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (event.key === 'ArrowUp') {
            (_d = (_c = event.currentTarget) === null || _c === void 0 ? void 0 : _c.previousElementSibling) === null || _d === void 0 ? void 0 : _d.focus();
        }
    }, []);
    return {
        onKeyDown: keyDownHandler,
        tabIndex: 0,
        role: 'button',
    };
}
