import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { MenuBody, MenuList, MenuSubheader, MenuHeader, SearchInput } from '@pocketlaw/tetris';
import { AssigneeList } from './AssigneeList';
import { useAssigneesField } from '../useAssigneesField';
export function AssigneeDropdown() {
    const [inputValue, setInputValue] = useState('');
    const { addAssignee, removeAssignee, selectedAssignees, unselectedAssignees, searchResult, search, } = useAssigneesField();
    const unSelected = inputValue ? searchResult : unselectedAssignees;
    const showAllUsersLabel = unselectedAssignees.length > 0 && !inputValue;
    const showSearchLabel = searchResult.length > 0 && inputValue;
    return (<Fragment>
      <MenuHeader>
        <SearchInput placeholder={t({
            message: 'Search users',
            comment: 'Assign users dropdown search placeholder',
        })} variant="line" value={inputValue} onChange={e => {
            setInputValue(e.target.value);
            search(e.target.value);
        }} onReset={() => setInputValue('')} stretch/>
      </MenuHeader>
      <MenuBody>
        <MenuList>
          {selectedAssignees.length > 0 && (<MenuSubheader>
              <Trans comment="Assign users dropdown selected users section title">Assigned</Trans>
            </MenuSubheader>)}
          <AssigneeList checked items={selectedAssignees} onChange={assignee => removeAssignee(assignee.id)}/>
          {showAllUsersLabel && (<MenuSubheader>
              <Trans comment="Assign users dropdown unselected users section title">
                All users
              </Trans>
            </MenuSubheader>)}
          {showSearchLabel && (<MenuSubheader>
              <Trans comment="Assign users dropdown unselected searched users section title">
                Filtered
              </Trans>
            </MenuSubheader>)}
          <AssigneeList items={unSelected} onChange={addAssignee}/>
        </MenuList>
      </MenuBody>
    </Fragment>);
}
