import { t } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDownloadCSV } from './useDownloadCSV';
export function CSVDownload() {
    const { download, loading, progress } = useDownloadCSV();
    const loadingText = t({
        message: `Downloading... ${progress}%`,
        comment: 'CSV download button: Loading label',
    });
    const text = t({
        message: 'Download',
        comment: 'CSV download button: Label',
    });
    return (<Button onClick={download} disabled={loading} appearance="ghost" size="small">
      <Falcon icon="download"/>
      {loading ? loadingText : text}
      {loading && <Loader />}
    </Button>);
}
