import { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
export function ReloadIndicator() {
    const [countdown, setCountdown] = useState(10);
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (countdown === 0) {
                clearInterval(countdownInterval);
                window.location.reload();
            }
            else {
                setCountdown(countdown - 1);
            }
        }, 1000);
        return () => clearInterval(countdownInterval);
    });
    return (<Box display="block">
      <Typography>
        <Trans comment="Page reload indicator">
          We will reload the page in {countdown} seconds...
        </Trans>
      </Typography>
    </Box>);
}
