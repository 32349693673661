var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { acknowledgeDocumentFlush } from './acknowledgeDocumentFlush';
import { documentFlush } from '../../documentFlush';
import { PleditorPlugin } from '../PleditorPlugin';
export const READ_ONLY_FLUSH_EVENT = 'rtc-offline';
export class HandleDocumentFlushedPlugin extends PleditorPlugin {
    static get pluginName() {
        return 'HandleDocumentFlushedPlugin';
    }
    static get requires() {
        return ['RealTimeCollaborationClient', 'Sessions', 'Users'];
    }
    constructor(editor) {
        super(editor);
        this.set('sessionId', null);
        this.set('socketId', null);
        this.set('userId', null);
    }
    init() {
        const { editor } = this;
        editor.decorate('enableReadOnlyMode');
        editor.once('enableReadOnlyMode', (_info, events) => {
            if (events.includes(READ_ONLY_FLUSH_EVENT)) {
                // Remove read only mode, we handle this by adding the RestrictedEditingMode plugin instead
                // This is because we can't flush the document when it is in read only mode
                editor.disableReadOnlyMode(READ_ONLY_FLUSH_EVENT);
                this.handleDocumentFlush();
            }
        });
    }
    afterInit() {
        const { editor } = this;
        const users = editor.plugins.get('Users');
        this.bind('userId').to(users, 'me', (user) => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : null; });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sessions = editor.plugins.get('Sessions');
        const realTimeCollaborationClient = editor.plugins.get('RealTimeCollaborationClient');
        // There's no purposed event for us to capture the current session- and socket id.
        // We know that a socket has been established when the below event is fired and a socket id requires
        // a session id to already be set.
        sessions.on('sessionAdd', (_, data) => {
            const { channelId } = data;
            if (channelId === this.documentId) {
                this.socketId = sessions.mySessionId;
                this.sessionId = realTimeCollaborationClient.sessionId;
            }
        });
    }
    handleDocumentFlush() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const { sessionId, socketId, userId } = this;
            try {
                const { state } = documentFlush();
                // The flush was initiated by the current user, so we don't need to fetch the flush reason
                if (state === 'initialized') {
                    documentFlush({ state: 'not-initialized' });
                    return;
                }
                documentFlush({ state: 'loading' });
                if (sessionId && socketId && userId) {
                    const acknowledgement = yield acknowledgeDocumentFlush(this.apiConnector.mutate, {
                        sessionId,
                        socketId,
                        userId,
                    });
                    documentFlush({
                        state: 'done',
                        reason: (_a = acknowledgement === null || acknowledgement === void 0 ? void 0 : acknowledgement.reason) !== null && _a !== void 0 ? _a : null,
                        initiatorUserId: (_b = acknowledgement === null || acknowledgement === void 0 ? void 0 : acknowledgement.userId) !== null && _b !== void 0 ? _b : null,
                    });
                }
                else {
                    documentFlush({ state: 'not-initialized' });
                }
            }
            catch (_c) {
                documentFlush({ state: 'not-initialized' });
            }
        });
    }
}
