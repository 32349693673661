import { useActiveDocument } from 'shared/domains/documents';
import { pluginList, coreTranslations, premiumFeaturesTranslations, getColorPickerColors, } from 'shared/domains/pleditor@next';
import { autosave } from './autosave';
import { usePleditorAssistantConfig } from '../../../hooks/usePleditorAssistantConfig';
import { renderAttachment, renderSelectAttachment } from '../../../plugins/Attachments';
import { useGetDocxConverterToken } from '../useGetDocxConverterToken';
import { useGetEditorToken } from '../useGetEditorToken';
export const useConfig = (options) => {
    const { id: documentId } = useActiveDocument();
    const getEditorToken = useGetEditorToken(documentId);
    const getDocxConverterToken = useGetDocxConverterToken(documentId);
    const { documentType, locked, clientPlugins = [], toolbarItems, balloonToolbarItems, refs, currentUser, featureAvailability, attachments = [], } = options;
    const { editorContainerRef, viewerContainerRef, viewerEditorElementRef, viewerSidebarContainerRef, sidebarContainerRef, } = refs;
    const uploadUrl = process.env.PLEDITOR_IMAGE_UPLOAD_URL;
    const importConverterUrl = process.env.PLEDITOR_IMPORT_CONVERTER_URL;
    const webSocketUrl = process.env.PLEDITOR_WS_URL;
    const bundleVersion = process.env.PLEDITOR_BUNDLE_VERSION;
    const cloudServicesConfig = {
        channelId: documentId,
        tokenUrl: getEditorToken,
        webSocketUrl,
        uploadUrl,
        bundleVersion,
    };
    const featureAvailabilityFallback = Object.assign({ suggestions: false }, featureAvailability);
    const assistantConfig = usePleditorAssistantConfig();
    const removePlugins = [];
    // TODO: Verify that we can actually **load** or **not load** RestrictedEditingMode dynamically
    if (documentType === 'freetext' && !locked) {
        removePlugins.push('RestrictedEditingMode');
    }
    const baseConfig = {
        documentId,
        currentUser,
        featureAvailability: featureAvailabilityFallback,
        translations: [coreTranslations, premiumFeaturesTranslations],
        plugins: [...pluginList, ...clientPlugins],
        removePlugins,
        toolbar: {
            shouldNotGroupWhenFull: false,
            items: toolbarItems,
        },
        balloonToolbar: {
            items: balloonToolbarItems,
        },
        autosave: {
            waitingTime: 3000,
            save: autosave,
        },
        // Plugin: CloudServices
        cloudServices: {
            tokenUrl: cloudServicesConfig.tokenUrl,
            uploadUrl: cloudServicesConfig.uploadUrl,
            webSocketUrl: cloudServicesConfig.webSocketUrl,
            bundleVersion: cloudServicesConfig.bundleVersion,
        },
        collaboration: {
            channelId: cloudServicesConfig.channelId,
        },
        // Plugin: Table
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
        },
        sidebar: {
            preventScrollOutOfView: true,
            container: sidebarContainerRef,
        },
        users: {
            colorsCount: 9,
        },
        // Plugin: Attachments
        attachments: {
            attachments,
            renderAttachment,
            renderSelectAttachment,
        },
        // Plugin: FontColor
        fontColor: {
            colors: getColorPickerColors(),
        },
        // Plugin: FontBackgroundColor
        fontBackgroundColor: {
            colors: getColorPickerColors(),
        },
        // Plugin: Assistant
        assistant: assistantConfig,
    };
    const freeTextConfig = {
        // Plugin: RevisionHistory
        revisionHistory: {
            resumeUnsavedRevision: true,
            editorContainer: editorContainerRef,
            viewerContainer: viewerContainerRef,
            viewerEditorElement: viewerEditorElementRef,
            viewerSidebarContainer: viewerSidebarContainerRef,
        },
        // Plugin: ImportWord
        importWord: {
            formatting: {},
            tokenUrl: getDocxConverterToken,
            converterUrl: importConverterUrl,
        },
        // Plugin: ExportWord
        exportWord: { tokenUrl: getDocxConverterToken },
        // Plugin: Image
        image: {
            toolbar: [
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'toggleImageCaption',
                '|',
                'resizeImage',
            ],
        },
    };
    if (documentType === 'composed') {
        return baseConfig;
    }
    return Object.assign(Object.assign({}, baseConfig), freeTextConfig);
};
