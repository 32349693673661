import { useReactiveVar } from '@apollo/client';
import { Switch } from '@pocketlaw/tetris';
import { featureFlagsVar } from '../../reactiveVar';
import { FlagState } from '../../types';
import { FlagText, StyledListItem } from './styled';
export function SettingRow(props) {
    const { flag } = props;
    const features = useReactiveVar(featureFlagsVar);
    const setFlag = (flagState) => {
        const currentItems = featureFlagsVar();
        featureFlagsVar(Object.assign(Object.assign({}, currentItems), { [flag]: {
                value: flagState,
            } }));
        window.localStorage.setItem(flag, flagState);
    };
    const handleChange = (checked) => {
        if (checked) {
            return setFlag(FlagState.ENABLED);
        }
        return setFlag(FlagState.DISABLED);
    };
    return (<StyledListItem>
      <Switch checked={features[flag].value === FlagState.ENABLED} onCheckedChange={handleChange}/>
      <FlagText> {flag}</FlagText>
    </StyledListItem>);
}
