import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
export const useClipboard = () => {
    const { addToast } = useToast();
    const copyToClipboard = (text) => {
        if (!text) {
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Copied to clipboard!',
                    comment: 'document assistant - assistant reply action - toast when reply has been copied to clipboard',
                }),
            });
        });
    };
    return { copyToClipboard };
};
