/**
 * Converts a cell value of any type to a string representation suitable for CSV export.
 * Handles various data types including strings, numbers, booleans, null/undefined,
 * arrays, and objects. For complex types like arrays and objects, it provides a
 * simplified string representation.
 *
 * @param {unknown} str - The cell value to be stringified
 * @returns {string} A string representation of the input value
 */
export function stringifyCellValue(str) {
    if (typeof str === 'string') {
        return str;
    }
    if (typeof str === 'number') {
        return str.toString();
    }
    if (typeof str === 'boolean') {
        return str.toString();
    }
    if (str === null || str === undefined) {
        return '';
    }
    if (Array.isArray(str)) {
        return str.join(', ');
    }
    if (typeof str === 'object') {
        return JSON.stringify(str);
    }
    return str.toString();
}
/**
 * Cleans and formats a string value for CSV output:
 * - Replaces newlines and carriage returns with spaces
 * - Removes double quotes
 * - Truncates to 1000 characters
 * - Wraps the result in double quotes
 * @param {string} val - The input string to clean
 * @returns {string} The cleaned and formatted string
 */
export function cleanValue(val) {
    return `"${val
        .replace(/[\n\r]+/g, ' ')
        .replace(/"/g, '')
        .substring(0, 1000)}"`;
}
export function downloadBlob(str, filename = 'download.csv') {
    // MS Excel requires the content to be prefixed with BOM in order to interpret it as utf-8
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.hidden = true;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
