import { gql } from '@apollo/client';
export const lastApprovalRequestQuery = gql `
  query lastApprovalRequest($documentId: ID!) {
    fileSystemDocument: fileSystemDocument(id: $documentId) {
      id
      lastApprovalRequest {
        id
        status
        createdAt
        requestedByUser {
          id
          name
        }
        approvers {
          id
          status
          user {
            id
            name
          }
        }
      }
    }
  }
`;
