import { t } from '@lingui/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { SearchStringFilter } from './SearchStringFilter';
export const entityTypeFilterLabel = () => t({
    message: 'Entity type',
    comment: 'Search entity type filter: Entity type, this is documents, drafts, folder, user',
});
export function EntityTypeFilter() {
    const items = [
        {
            id: SearchEntityTypeEnum.Document,
            title: t({
                message: 'Documents',
                comment: 'Search entity type filter: Any type of document',
            }),
        },
        {
            id: SearchEntityTypeEnum.Draft,
            title: t({
                message: 'Drafts',
                comment: 'Search entity type filter: Draft',
            }),
        },
        {
            id: SearchEntityTypeEnum.Folder,
            title: t({
                message: 'Folders',
                comment: 'Search entity type filter: Folder',
            }),
        },
        {
            id: SearchEntityTypeEnum.Task,
            title: t({
                message: 'Tasks',
                comment: 'Search entity type filter: Task',
            }),
        },
        {
            id: SearchEntityTypeEnum.User,
            title: t({
                message: 'Users',
                comment: 'Search entity type filter: User',
            }),
        },
    ];
    return <SearchStringFilter name="entityType" items={items} label={entityTypeFilterLabel()}/>;
}
