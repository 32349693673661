import { t } from '@lingui/macro';
import { Box, EmptyState, List, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
import { getFeedbackLabel } from 'app/domains/review/utils';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { Feedback } from '../Feedback';
export function OpenFeedback() {
    const { review } = usePlai();
    const { feedbacks = [] } = review || {};
    const openFeedbacks = feedbacks.filter(({ feedback }) => feedback.status === ReviewFeedbackStatus.Pending);
    const criticalFeedback = {
        title: getFeedbackLabel('critical'),
        items: openFeedbacks.filter(({ feedback }) => feedback.criticality === 'Critical'),
    };
    const reviseFeedback = {
        title: getFeedbackLabel('minor'),
        items: openFeedbacks.filter(({ feedback }) => feedback.criticality === 'Minor'),
    };
    const notableFeedback = {
        title: getFeedbackLabel('notable'),
        items: openFeedbacks.filter(({ feedback }) => feedback.criticality === 'Notable'),
    };
    const feedbackItems = [criticalFeedback, reviseFeedback, notableFeedback].filter(({ items }) => items.length > 0);
    if (openFeedbacks.length === 0) {
        return (<EmptyState withAvatar icon={<Falcon icon="sparkles"/>} title={t({
                comment: 'Contract review open issues - Empty state title',
                message: 'All issues resolved',
            })} description={t({
                comment: 'Contract review open issues - Empty state description',
                message: 'The review is completed, well done.',
            })}/>);
    }
    return (<Box flexDirection="column">
      {feedbackItems.map(({ title, items }) => (<List key={title}>
          <Typography $fontSize="small">{title}</Typography>
          {items.map(feedbackItem => (<Feedback key={feedbackItem.feedback.id} feedback={feedbackItem}/>))}
        </List>))}
    </Box>);
}
