var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useHistory, useParams } from 'react-router-dom';
import { FileSystemManager, useS3FileUpload, } from 'app/domains/filesystem';
import { useAutoTagDocuments } from 'app/domains/metadata';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { useCreateDocument } from './useCreateDocument';
const nameFromFile = (file) => file.name.slice(0, file.name.lastIndexOf('.'));
export function UploadDocumentDialog({ files: controlledFiles, onCreated, onClose, withRedirect = true, }) {
    const { folderId } = useParams();
    const [files, setFiles] = useState(controlledFiles);
    const { upload } = useS3FileUpload();
    const [createDocument] = useCreateDocument({ onCreated });
    const { addToast } = useToast();
    const history = useHistory();
    const autoTag = useAutoTagDocuments();
    const type = EntryKind.Document;
    const handleSubmit = useCallback((values, { resetForm, setSubmitting }) => {
        upload(files.slice(0, 1))
            .then(results => {
            const result = results[0];
            const file = result.uploadedFile;
            return createDocument(Object.assign(Object.assign({}, values), { file }));
        })
            .then((_a) => __awaiter(this, [_a], void 0, function* ({ data }) {
            addToast({ title: t `Document uploaded`, appearance: 'success' });
            const nextFiles = files.slice(1);
            const { document } = data || {};
            if (values.autoTag === true && (document === null || document === void 0 ? void 0 : document.id)) {
                yield autoTag.mutate([document.id]);
            }
            if (nextFiles.length > 0) {
                setFiles(nextFiles);
                resetForm({ values: Object.assign(Object.assign({}, values), { name: nameFromFile(nextFiles[0]) }) });
            }
            else {
                const onCloseData = Array.isArray(data) ? data[0] : data;
                onClose(onCloseData);
                if (withRedirect && values.parentId) {
                    history.push(`/files/${values.parentId}`);
                }
                else if (withRedirect) {
                    history.push(`/files`);
                }
            }
        }))
            .catch(() => {
            setSubmitting(false);
            addToast({
                title: t({
                    message: 'Something went wrong, please try again later',
                    comment: 'Upload document dialog error toast',
                }),
                appearance: 'danger',
            });
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [upload, createDocument, files, addToast, onClose]);
    return (<FileSystemManager entryType={type} defaultParentId={folderId} defaultName={nameFromFile(files[0])} onSubmit={handleSubmit} onClose={onClose}/>);
}
