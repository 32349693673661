import { t, Trans } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { emails } from 'shared/domains/config';
export function NoCreditsAlert() {
    const emailHref = `mailto:${emails.support}`;
    return (<Alert appearance="warning" title={t({
            comment: 'Warning title when document assistant credit is empty',
            message: 'No credits',
        })} message={t({
            comment: 'Warning message when document assistant credit is empty',
            message: 'Contact support to add new credits.',
        })}>
      <Button as="a" appearance="secondary" size="small" href={emailHref}>
        <Trans comment="Warning button label when document assistant credit is empty">
          Contact support
        </Trans>
      </Button>
    </Alert>);
}
