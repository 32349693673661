import { Trans, t } from '@lingui/macro';
import { Box, EmptyState, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Item } from './Item';
import { ChatDateGroup, groupChatsByDate } from './utils';
import { useAssistant } from '../../AssistantProvider';
import { AssistantChatContainer } from '../styled';
export function History() {
    const { chats } = useAssistant();
    const getLabelFromDateGroup = (dateGroup) => {
        switch (dateGroup) {
            case ChatDateGroup.Today:
                return t({
                    comment: 'Assistant chat conversation history - Chats updated today',
                    message: 'Today',
                });
            case ChatDateGroup.Yesterday:
                return t({
                    comment: 'Assistant chat conversation history - Chats updated yesterday',
                    message: 'Yesterday',
                });
            case ChatDateGroup.LastWeek:
                return t({
                    comment: 'Assistant chat conversation history - Chats updated last week',
                    message: 'Last week',
                });
            case ChatDateGroup.LastMonth:
                return t({
                    comment: 'Assistant chat conversation history - Chats updated last month',
                    message: 'Last month',
                });
            case ChatDateGroup.OverOneMonth:
                return t({
                    comment: 'Assistant chat conversation history - Chats updated over a month ago',
                    message: 'Over a month ago',
                });
            default:
                return '';
        }
    };
    const getDateFormatFromDateGroup = (dateGroup) => {
        switch (dateGroup) {
            case ChatDateGroup.Today:
            case ChatDateGroup.Yesterday:
                return {
                    hour: 'numeric',
                    minute: 'numeric',
                };
            case ChatDateGroup.LastWeek:
            case ChatDateGroup.LastMonth:
            case ChatDateGroup.OverOneMonth:
            default:
                return {
                    day: 'numeric',
                    month: 'long',
                };
        }
    };
    if (chats.length === 0) {
        return (<AssistantChatContainer>
        <EmptyState withAvatar icon={<Falcon icon="comments"/>} title={t({
                comment: 'Assistant conversation history - empty state title',
                message: 'No conversations',
            })} description={t({
                comment: 'Assistant conversation history - empty state description',
                message: 'Start a conversation with Plai and it will show up here',
            })}/>
      </AssistantChatContainer>);
    }
    const chatGroups = groupChatsByDate(chats);
    return (<AssistantChatContainer $withScroll>
      <Box flexDirection="column" gap="2xl">
        <Typography $fontSize="small" $appearance="100" $fontWeight="semibold">
          <Trans comment="Assistant conversation history - conversation history label">
            Previous conversations
          </Trans>
        </Typography>
        {chatGroups.map(({ key: groupKey, items: groupChats }) => (<Box key={groupKey} flexDirection="column" gap="sm">
            <Typography $fontSize="tiny" $appearance="100">
              {getLabelFromDateGroup(groupKey)}
            </Typography>
            <Box flexDirection="column" gap="md">
              {groupChats.map(chat => (<Item key={chat.id} chat={chat} dateFormat={getDateFormatFromDateGroup(groupKey)}/>))}
            </Box>
          </Box>))}
      </Box>
    </AssistantChatContainer>);
}
