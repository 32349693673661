import { Box, Tooltip } from '@pocketlaw/tetris';
import { useMessageContext } from '../MessageContext/useMessageContext';
import { Superscript as SuperscriptElement } from './styled';
export const FOOTNOTE_ATTRIBUTE = 'data-footnote-definition';
export function Superscript(props) {
    const { [FOOTNOTE_ATTRIBUTE]: footnote, children } = props;
    const { onFootnoteClick } = useMessageContext();
    if (!footnote) {
        return <span>{children}</span>;
    }
    const handleClick = onFootnoteClick ? () => onFootnoteClick(footnote) : undefined;
    return (<Tooltip title={footnote} width={200}>
      <SuperscriptElement $hasOnclick={Boolean(handleClick)} onClick={handleClick}>
        <Box alignItems="center" justifyContent="center" style={{ lineHeight: 'inherit' }}>
          {children}
        </Box>
      </SuperscriptElement>
    </Tooltip>);
}
