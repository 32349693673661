import { Drawer, breakpoints, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
const DRAWER_WIDTH = pxToRem(440);
const DRAWER_WIDTH_XL = pxToRem(540);
const DRAWER_WIDTH_XXL = pxToRem(600);
const DRAWER_WIDTH_EXPANDED = pxToRem(740);
const TRANSITION = 'all 225ms ease-in-out';
export const DrawerInnerContainer = styled.div `
  position: relative;
  height: 100%;
  transform: translateX(100%);
  border-left: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
  width: 100%;
`;
export const DrawerContainerOffset = styled.div `
  z-index: 1;
  position: relative;
  right: 0;
  top: 0;
  height: 100%;
  flex-shrink: 0;
  max-width: 100%;
  width: 0;

  transition: ${TRANSITION};

  ${props => props.$open &&
    `
    ${breakpoints.md} {
      width: ${DRAWER_WIDTH};
    }

    ${breakpoints.xl} {
      width: ${DRAWER_WIDTH_XL};
    }

    ${breakpoints.xxl} {
      width: ${DRAWER_WIDTH_XXL};
    }
  `}
`;
export const DrawerContainer = styled.div `
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
  max-width: 100%;
  width: 0;

  background-color: ${themeGet('colors.background.50')};
  box-shadow: ${themeGet('dropShadow.400')};

  transition: ${TRANSITION};

  ${props => props.$open &&
    `
    width: ${DRAWER_WIDTH};
    transform: translateX(${pxToRem(0)});

    ${breakpoints.xl} {
      width: ${DRAWER_WIDTH_XL};
    }

    ${breakpoints.xxl} {
      width: ${DRAWER_WIDTH_XXL};
    }

    ${DrawerInnerContainer} {
      transform: translateX(0%);
    }
  `}

  ${props => props.$expanded &&
    `
    width: ${DRAWER_WIDTH_EXPANDED};
  `}

  ${breakpoints.md} {
    background-color: ${themeGet('colors.background.50')};
    box-shadow: unset;
    height: 100vh;

    ${props => props.$expanded &&
    `
      box-shadow: ${themeGet('dropShadow.400')};
      width: ${DRAWER_WIDTH_EXPANDED};
    `}
  }
`;
export const StyledDrawer = styled(Drawer) `
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.background.50')};
`;
