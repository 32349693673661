import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { pluginList, coreTranslations, premiumFeaturesTranslations, } from 'shared/domains/pleditor@next';
import { GetDocumentHtmlBodyDocument } from './query.gql';
import { AppApiConnector } from '../../../plugins/AppApiConnector';
import { InsertAttachmentWidgetUI, renderAttachment, renderSelectAttachment, } from '../../../plugins/Attachments';
import { EditMode } from '../../../plugins/EditMode';
import { FlexGap } from '../../../plugins/FlexGap';
import { InlineComment } from '../../../plugins/InlineComment';
import { OffsetPlugin } from '../../../plugins/OffsetPlugin';
import { ReviewPlugin } from '../../../plugins/Review';
import { ToastHandler } from '../../../plugins/ToastHandler';
import { ToggleComments } from '../../../plugins/ToggleComments';
import { TopToolbar } from '../../../plugins/TopToolbar';
import { useGetEditorToken } from '../../hooks/useGetEditorToken';
export const useSandboxData = (options) => {
    var _a;
    const { refs, toolbarItems, balloonToolbarItems, currentUser, featureAvailability, attachments = [], } = options;
    const { id: documentId } = useActiveDocument();
    const { sidebarContainerRef } = refs;
    const imageUploadUrl = process.env.PLEDITOR_IMAGE_UPLOAD_URL;
    const { data, loading } = useQuery(GetDocumentHtmlBodyDocument, { variables: { documentId } });
    const htmlBody = ((_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.htmlBody) || '';
    const getEditorToken = useGetEditorToken(documentId);
    const featureAvailabilityFallback = Object.assign({ suggestions: false }, featureAvailability);
    const config = {
        documentId,
        currentUser,
        featureAvailability: featureAvailabilityFallback,
        translations: [coreTranslations, premiumFeaturesTranslations],
        plugins: [
            ...pluginList,
            AppApiConnector,
            TopToolbar,
            FlexGap,
            OffsetPlugin,
            ToastHandler,
            ToggleComments,
            InlineComment,
            EditMode,
            ReviewPlugin,
            InsertAttachmentWidgetUI,
        ],
        // Remove RTC- and Collaboration plugins as they will not work without a backend
        removePlugins: [
            'RestrictedEditingMode',
            'Comments',
            'TrackChanges',
            'RevisionTracker',
            'RevisionHistory',
            'RealTimeCollaborativeEditing',
            'RealTimeCollaborativeComments',
            'RealTimeCollaborativeTrackChanges',
            'RealTimeCollaborativeRevisionHistory',
        ],
        toolbar: {
            shouldNotGroupWhenFull: false,
            items: toolbarItems,
        },
        balloonToolbar: {
            items: balloonToolbarItems,
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
        },
        sidebar: {
            preventScrollOutOfView: true,
            container: sidebarContainerRef,
        },
        cloudServices: {
            tokenUrl: getEditorToken,
            uploadUrl: imageUploadUrl,
        },
        image: {
            toolbar: [
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'toggleImageCaption',
                '|',
                'resizeImage',
            ],
        },
        attachments: {
            attachments,
            renderAttachment,
            renderSelectAttachment,
        },
    };
    return { config, htmlBody, loading };
};
