import { t } from '@lingui/macro';
import { IconButton, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlai } from 'app/domains/review';
export function Discard(props) {
    const { feedbackId } = props;
    const { discardFeedback } = usePlai();
    const handleDiscardIssue = () => discardFeedback(feedbackId);
    return (<Tooltip side="top" title={t({
            message: 'Discard issue',
            comment: 'Plai Review - Issue Item - discard - button tooltip',
        })}>
      <IconButton size="small" appearance="ghost" onClick={handleDiscardIssue}>
        <Falcon icon="xmark"/>
      </IconButton>
    </Tooltip>);
}
