import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Box, MenuBody, MenuItem, MenuList, Switch } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { FormSubmitButton } from './FormSubmitButton';
export function BooleanField() {
    const [field, , helpers] = useField({
        name: 'value',
        type: 'checkbox',
    });
    const { value } = field;
    const handleOnChange = (checked) => {
        helpers.setValue(checked);
    };
    const trueLabel = t({ message: 'Yes', comment: 'Metadata: Boolean true value' });
    const falseLabel = t({ message: 'No', comment: 'Metadata: Boolean false value' });
    return (<Fragment>
      <MenuBody>
        <MenuList>
          <MenuItem as="label">
            {value ? trueLabel : falseLabel}
            <Box ml="auto">
              <Switch checked={value} onCheckedChange={handleOnChange}/>
            </Box>
          </MenuItem>
        </MenuList>
      </MenuBody>
      <FormSubmitButton />
    </Fragment>);
}
