import { useState, useEffect } from 'react';
import { validateEditorBundleVersion } from './validateEditorBundleVersion';
const initialState = { loading: true, valid: undefined, error: undefined };
/**
 * Hook to validate editor bundle version with current bundle version on mount
 */
export function useEditorVersionManifest() {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        const controller = new AbortController();
        validateEditorBundleVersion(controller.signal)
            .then(data => {
            setState({ loading: false, valid: data, error: undefined });
        })
            .catch(error => {
            setState({ loading: false, valid: undefined, error });
        });
        return () => {
            controller.abort();
        };
    }, []);
    return state;
}
