import { differenceInMinutes } from 'date-fns';
export const groupMessages = (messages) => messages.reduce((acc, message, index, collection) => {
    if (index === 0) {
        return [[message]];
    }
    const prevMessage = collection[index - 1];
    const sameSender = prevMessage.sender === message.sender;
    if (!sameSender) {
        return [...acc, [message]];
    }
    const withinOneHour = differenceInMinutes(message.timestamp, prevMessage.timestamp) <= 60;
    if (withinOneHour) {
        const currentGroup = acc[acc.length - 1];
        currentGroup.push(message);
        acc.pop();
        return [...acc, currentGroup];
    }
    return [...acc, [message]];
}, []);
export const addMetadataToMessages = (groupedMessages) => groupedMessages.map((group, groupIndex, groupCollection) => group.map((message, messageIndex, messageCollection) => (Object.assign(Object.assign({}, message), { isFirst: messageIndex === 0, isLast: messageIndex === messageCollection.length - 1, isLatestMessage: messageIndex === messageCollection.length - 1 && groupIndex === groupCollection.length - 1 }))));
