import { useUnmount } from 'react-use';
import { useAssistant } from 'app/domains/assistant';
export function useResetFuzzySearchOnUnmount() {
    const { resetFuzzySearch } = useAssistant();
    return useUnmount(() => {
        if (resetFuzzySearch) {
            resetFuzzySearch();
        }
    });
}
