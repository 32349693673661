import { useReactiveVar } from '@apollo/client';
import { documentFlush } from 'shared/domains/documents';
import { FlushInfoModal } from './FlushReasonModal';
export function FlushReasonModal() {
    const flush = useReactiveVar(documentFlush);
    if (flush.state !== 'done') {
        return null;
    }
    const { reason, initiatorUserId } = flush;
    return <FlushInfoModal reason={reason} initiatorUserId={initiatorUserId}/>;
}
