import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function entityTypeToAvatarType(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return 'file';
        case SearchEntityTypeEnum.Draft:
            return 'file';
        case SearchEntityTypeEnum.Folder:
            return 'folder';
        case SearchEntityTypeEnum.Task:
            return 'file';
        case SearchEntityTypeEnum.Template:
            return 'file';
        case SearchEntityTypeEnum.User:
            return 'file';
        default:
            return 'file';
    }
}
