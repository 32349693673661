import { t, select } from '@lingui/macro';
import { MenuItem, Loader, MenuItemText } from '@pocketlaw/tetris';
import { useUpdateComposerTeamPermissions, useComposersTable } from 'app/domains/templates';
export function UpdateTeamAccessMenuItem(props) {
    const { type, teamIds, withSubtitle = false } = props;
    const { drawerData } = useComposersTable();
    const composerIds = (drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds) || [];
    const { updateComposerTeamPermissions, loading, refetching } = useUpdateComposerTeamPermissions();
    const handleClick = () => updateComposerTeamPermissions({ type, teamIds, composerIds });
    const title = t({
        comment: 'Contract access dropdown option: Title',
        message: select(type, {
            add: 'Grant access',
            remove: 'Remove access',
            other: 'Grant access',
        }),
    });
    const subtitle = t({
        comment: 'Contract team access dropdown option: Subtitle',
        message: 'For all teams',
    });
    return (<MenuItem disabled={loading || refetching} onClick={handleClick}>
      <MenuItemText primary={title} secondary={withSubtitle ? subtitle : undefined}/>
      {loading && <Loader />}
    </MenuItem>);
}
