import { t } from '@lingui/macro';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { getApproverStatusBadge } from './utils';
import { SectionList, SectionListItem } from '../../components';
export function DocumentApprovers() {
    const { approvers } = useDocumentApproversWithStatus();
    return (<SectionList title={t({ comment: 'Document approvers', message: 'Approvers' })}>
      {approvers.map(approver => (<SectionListItem key={approver.userId} primary={approver.name} secondary={approver.email} endElement={getApproverStatusBadge(approver.status)}/>))}
    </SectionList>);
}
