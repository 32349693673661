var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useActiveDocument } from 'shared/domains/documents';
import { GetDocumentAttachmentSignedUrlDocument } from './query.gql';
export function useDownloadDocumentAttachment() {
    const { id: documentId } = useActiveDocument();
    const { addToast } = useToast();
    const [query, { loading: downloading }] = useLazyQuery(GetDocumentAttachmentSignedUrlDocument);
    const downloadAttachment = (attachmentId) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield query({ variables: { documentId } });
            const { documentAttachments = [] } = data || {};
            const attachments = documentAttachments || [];
            const attachment = attachments.find(file => (file === null || file === void 0 ? void 0 : file.id) === attachmentId);
            if (!((_a = attachment === null || attachment === void 0 ? void 0 : attachment.signed) === null || _a === void 0 ? void 0 : _a.url)) {
                throw Error('Failed to download attachment as no URL was found');
            }
            window.location.href = attachment.signed.url;
        }
        catch (error) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Notification when attachment download could not go through',
                    message: 'Attachment could not be downloaded. Try again.',
                }),
            });
        }
    });
    return { downloading, downloadAttachment };
}
