import { Box, Button, Switch } from '@pocketlaw/tetris';
import { usePleditor } from 'shared/domains/documents';
export function SandboxButton() {
    const { pleditorState, sandboxMode, sandboxModeAvailable, setSandboxMode, unsetPleditor } = usePleditor();
    const handleClick = () => {
        unsetPleditor();
        setSandboxMode(!sandboxMode);
    };
    if (!sandboxModeAvailable) {
        return null;
    }
    return (<Button appearance="secondary" disabled={pleditorState !== 'initialized'} onClick={handleClick}>
      <Box alignItems="center" justifyContent="center" gap="sm">
        Sandbox Mode
        <Switch size="sm" checked={sandboxMode}/>
      </Box>
    </Button>);
}
