import { Children, Fragment, createElement } from 'react';
import toReact from 'rehype-react';
import sanitize from 'rehype-sanitize';
import gfm from 'remark-gfm';
import parse from 'remark-parse';
import toHtml from 'remark-rehype';
import { unified } from 'unified';
import { FOOTNOTE_ATTRIBUTE, Superscript } from './Superscript';
import { footnotesToQuotes } from './footnotesToQuotes';
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Anchor, Strong, Emphasis, Delete, BlockQuote, Hairline, UnorderedList, OrderedList, ListItem, TableRow, TableHead, TableDataCell, Table, Paragraph, } from './styled';
const componentMapping = {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    p: Paragraph,
    a: Anchor,
    strong: Strong,
    em: Emphasis,
    del: Delete,
    blockquote: BlockQuote,
    hr: Hairline,
    ul: UnorderedList,
    ol: OrderedList,
    li: ListItem,
    table: Table,
    tr: TableRow,
    th: TableHead,
    td: TableDataCell,
    sup: Superscript,
};
const markupSchema = {
    ancestors: {
        tbody: ['table'],
        td: ['table'],
        th: ['table'],
        thead: ['table'],
        tr: ['table'],
    },
    attributes: {
        a: ['href'],
        blockquote: ['cite'],
        del: ['cite'],
        sup: [FOOTNOTE_ATTRIBUTE],
        '*': [],
    },
    protocols: {
        cite: ['http', 'https'],
        href: ['http', 'https', 'irc', 'ircs', 'mailto', 'xmpp'],
    },
    strip: ['script', 'code', 'pre'],
    tagNames: [
        'a',
        'blockquote',
        'br',
        'del',
        'div',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hr',
        'li',
        'ol',
        'p',
        'strong',
        'table',
        'tbody',
        'td',
        'th',
        'thead',
        'tr',
        'ul',
        'sup',
    ],
};
const mapChildren = (children) => Children.map(children, child => {
    if (child.type === Fragment) {
        return mapChildren(child.props.children);
    }
    return child;
});
const getMarkupSchema = (components) => {
    const modifiedMarkupSchema = Object.assign({}, markupSchema);
    components.forEach(component => {
        const { tagName, attributes = [] } = component;
        modifiedMarkupSchema.tagNames.push(tagName);
        if (!modifiedMarkupSchema.attributes[tagName]) {
            modifiedMarkupSchema.attributes[tagName] = [];
        }
        modifiedMarkupSchema.attributes[tagName].push(...attributes);
    });
    return modifiedMarkupSchema;
};
const getMarkdownProcessor = (components) => {
    const schema = getMarkupSchema(components);
    const basePlugins = [footnotesToQuotes];
    const extraPlugins = components.flatMap(component => component.plugins || []);
    const plugins = [...basePlugins, ...extraPlugins];
    const markdownProcessor = unified()
        .use(parse)
        .use(gfm)
        .use(plugins)
        .use(toHtml)
        .use(sanitize, schema);
    return markdownProcessor;
};
const getCustomComponentMapping = (components) => components.reduce((acc, component) => {
    acc[component.tagName] = component.component;
    return acc;
}, {});
const getReactProcessor = (components) => {
    const customComponentMapping = getCustomComponentMapping(components);
    const reactProcessor = getMarkdownProcessor(components).use(toReact, {
        Fragment,
        createElement,
        components: Object.assign(Object.assign({}, componentMapping), customComponentMapping),
    });
    return reactProcessor;
};
export function markdownToReact(options) {
    const { markdown, components = [] } = options;
    const { result } = getReactProcessor(components).processSync(markdown);
    const children = mapChildren(result);
    if (Children.count(children) === 0) {
        return undefined;
    }
    return children;
}
