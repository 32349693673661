import { t } from '@lingui/macro';
import { ChatMessageRole } from 'shared/domains/apollo/generated/types';
export const normalizeChatMessages = (messages) => messages.map(message => {
    if (message.role === ChatMessageRole.Assistant) {
        return {
            id: message.id,
            sender: t({ comment: 'Plai', message: 'Plai' }),
            timestamp: message.createdAt || new Date().toISOString(),
            markdown: message.content,
            role: message.role,
            type: message.type,
            status: message.status,
            feedbackScore: message.feedbackScore,
        };
    }
    return {
        id: message.id,
        sender: t({ comment: 'You', message: 'You' }),
        timestamp: message.createdAt || new Date().toISOString(),
        markdown: message.content,
        role: message.role,
        type: message.type,
        status: message.status,
        feedbackScore: message.feedbackScore,
    };
});
