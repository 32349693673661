import { useState } from 'react';
import { useMobile } from 'shared/domains/common-ui';
import { UserAccountMenuContent } from './UserAccountMenuContent';
import { UserCard } from '../UserCard';
import { StyledMenu } from './styled';
export function UserAccountMenu() {
    const [open, setOpen] = useState(false);
    const isMobile = useMobile();
    const toggle = () => setOpen(!open);
    return (<StyledMenu open={open} onClose={toggle} options={{
            placement: isMobile ? 'top' : 'left',
        }} target={<UserCard isActive={open} onClick={toggle}/>}>
      <UserAccountMenuContent onClose={toggle}/>
    </StyledMenu>);
}
