var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { getTypename } from './getTypename';
import { updateUserAccessMutation } from './mutation';
export function useUpdateUserAccess(entryId) {
    var _a;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const [mutate, state] = useMutation(updateUserAccessMutation);
    const handleMutation = (handlerOptions) => __awaiter(this, void 0, void 0, function* () {
        const { toCreate, toUpdate, toDelete } = handlerOptions;
        const typename = yield getTypename(entryId);
        return mutate({
            variables: {
                toDelete,
                toCreate: toCreate.map(({ userId, actions }) => ({
                    userId,
                    actions,
                    companyId,
                    entryId,
                })),
                toUpdate: toUpdate.map(({ id, actions }) => ({
                    id,
                    actions,
                    entryId,
                })),
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({ __typename: typename, id: entryId }),
                    fields: {
                        acls(existing, { readField, toReference }) {
                            const added = data === null || data === void 0 ? void 0 : data.createdACL.map(acl => toReference(acl, true));
                            function isNotDeleted(acl) {
                                return !toDelete.includes(readField('id', acl) || '');
                            }
                            return existing.filter(isNotDeleted).concat(added);
                        },
                    },
                });
            },
        });
    });
    return {
        mutate: handleMutation,
        state,
    };
}
