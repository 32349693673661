import { useDocumentMetadata } from './useDocumentMetadata';
/**
 * Returns a metadata property and possible pair by ID
 * @param id string
 * @returns Metadata field
 */
export function useDocumentMetadataField(id) {
    const { data, documentId } = useDocumentMetadata();
    const { error, loading, properties } = data;
    // NOTE: The property is guaranteed at this stage in the code.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const metadataProperty = properties.find(({ property }) => property.id === id);
    return {
        documentId,
        error,
        loading,
        metadata: metadataProperty,
    };
}
