import { gql } from '@apollo/client';
const UpdateUserAccessResponse = gql `
  fragment UpdateUserAccessResponse on FileSystemAcl {
    id
    userId
    actions
    user {
      id
      name
      email
    }
  }
`;
export const updateUserAccessMutation = gql `
  mutation updateUserAccess(
    $toCreate: [FileSystemAclInsertInput!]!
    $toUpdate: [FileSystemAclRelativeSetInput!]!
    $toDelete: [ID!]!
  ) {
    createdACL: createFileSystemAcls(input: $toCreate) {
      ...UpdateUserAccessResponse
    }

    updateFileSystemAcls(input: $toUpdate) {
      ...UpdateUserAccessResponse
    }

    deletedACL: deleteFileSystemAcls(ids: $toDelete) {
      affected
    }

    ${UpdateUserAccessResponse}
  }
`;
