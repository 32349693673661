import { t } from '@lingui/macro';
import { SearchAggregationsAllowedPropertiesForDateHistogramAggregation as DateHistogramAllowedProperties, SearchAggregationDateInterval, } from 'shared/domains/apollo/generated/types';
export function allowedPropertiesTranslations(key) {
    const translations = {
        [DateHistogramAllowedProperties.CreatedAt]: t({
            message: 'Document creation date',
            comment: 'Insights: Date histogram allowed fields: CreatedAt',
        }),
        [DateHistogramAllowedProperties.MetadataDocumentDateSigned]: t({
            message: 'Date signed',
            comment: 'Insights: Date histogram allowed fields: MetadataDocumentDateSigned',
        }),
        [DateHistogramAllowedProperties.MetadataDocumentNoticeDate]: t({
            message: 'Notice date',
            comment: 'Insights: Date histogram allowed fields: MetadataDocumentNoticeDate',
        }),
        [DateHistogramAllowedProperties.MetadataDocumentProbationaryPeriodExpirationDate]: t({
            message: 'Probationary period expiration date',
            comment: 'Insights: Date histogram allowed fields: MetadataDocumentProbationaryPeriodExpirationDate',
        }),
        [DateHistogramAllowedProperties.MetadataDocumentStartDate]: t({
            message: 'Start date',
            comment: 'Insights: Date histogram allowed fields: MetadataDocumentStartDate',
        }),
        [DateHistogramAllowedProperties.MetadataDocumentTerminationDate]: t({
            message: 'Termination date',
            comment: 'Insights: Date histogram allowed fields: MetadataDocumentTerminationDate',
        }),
        [DateHistogramAllowedProperties.UpdatedAt]: t({
            message: 'Document updated at',
            comment: 'Insights: Date histogram allowed fields: UpdatedAt',
        }),
    };
    return translations[key];
}
export function intervalTranslations(interval) {
    const translations = {
        [SearchAggregationDateInterval.Day]: t({
            message: 'Day',
            comment: 'Insights: Date histogram interval',
        }),
        [SearchAggregationDateInterval.Month]: t({
            message: 'Month',
            comment: 'Insights: Date histogram interval',
        }),
        [SearchAggregationDateInterval.Quarter]: t({
            message: 'Quarter',
            comment: 'Insights: Date histogram interval',
        }),
        [SearchAggregationDateInterval.Week]: t({
            message: 'Week',
            comment: 'Insights: Date histogram interval',
        }),
        [SearchAggregationDateInterval.Year]: t({
            message: 'Year',
            comment: 'Insights: Date histogram interval',
        }),
    };
    return translations[interval];
}
