import { plural, t } from '@lingui/macro';
import { Chip, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDocumentLocked } from 'app/domains/documents';
import { useActiveDocument, useCurrentCollaborators, useGetCurrentUser, } from 'shared/domains/documents';
export function CollaboratingChip() {
    const { id } = useActiveDocument();
    const currentUser = useGetCurrentUser();
    const { locked: isDocumentLocked } = useDocumentLocked();
    const { isAnotherUserEditing, currentCollaboratorsAmount } = useCurrentCollaborators({
        userId: currentUser.id,
        documentId: id,
    });
    if (isAnotherUserEditing && !isDocumentLocked) {
        return (<Tooltip side="bottom" title={t({
                comment: 'Document has multiple active collaborators - chip tooltip',
                message: 'There are currently other users editing this document.',
            })}>
        <Chip appearance="secondary" icon={<Falcon icon="pen"/>} label={t({
                comment: 'Document has multiple active collaborators - chip label',
                message: 'Currently editing',
            })} secondary={t({
                comment: 'Document has multiple active collaborators - chip label secondary - amount of users',
                message: plural(currentCollaboratorsAmount, {
                    one: `${currentCollaboratorsAmount} user`,
                    other: `${currentCollaboratorsAmount} users`,
                }),
            })}/>
      </Tooltip>);
    }
    return null;
}
