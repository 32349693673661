import { Drawer, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledDrawer = styled(Drawer) `
  width: ${pxToRem(400)};
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.background.50')};
  box-shadow: ${themeGet('dropShadow.400')};
`;
export const LoaderContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
