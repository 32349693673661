import { AssistantChat, AssistantViewType, useAssistant, AssistantChatHeaderActions, } from 'app/domains/assistant';
import { DocumentAi, DocumentDrawerHeader } from 'shared/domains/documents';
import { Content, DrawerContainer } from './styled';
export function AssistantDrawer(props) {
    const { onClose } = props;
    const nestedViews = [
        AssistantViewType.Chat,
        AssistantViewType.Suggestions,
        AssistantViewType.Conversations,
    ];
    const { view, goToDashboard } = useAssistant();
    if (nestedViews.includes(view)) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" onClose={onClose} actions={<AssistantChatHeaderActions />} onGoBack={goToDashboard}/>
        <Content>
          <AssistantChat />
        </Content>
      </DrawerContainer>);
    }
    return (<DrawerContainer>
      <DocumentDrawerHeader type="root" actions={<AssistantChatHeaderActions />} onClose={onClose}/>
      <DocumentAi>
        <Content>
          <AssistantChat />
        </Content>
      </DocumentAi>
    </DrawerContainer>);
}
