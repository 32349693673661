import { useEffect, useRef } from 'react';
import lodashThrottle from 'lodash.throttle';
import { ChatWrapperProvider } from './Provider';
import { ChatContainer, Container, InputContainer } from './styled';
const AUTOSCROLL_TOLERANCE = 75;
export function ChatWrapper(props) {
    const { chat, input, className } = props;
    const autoscroll = useRef(true);
    const scrollerRef = useRef(null);
    const innerRef = useRef(null);
    const handleScroll = (event) => {
        if (event.target instanceof HTMLElement) {
            const { clientHeight, scrollHeight, scrollTop } = event.target;
            autoscroll.current = scrollHeight - (clientHeight + scrollTop) < AUTOSCROLL_TOLERANCE;
        }
    };
    const scrollToBottom = (behavior) => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTo({
                top: scrollerRef.current.scrollHeight,
                behavior,
            });
        }
    };
    /**
     *  This is used for scrolling to the bottom when the user sends a message
     * Without the setTimeout the scroll will not work because we add the message to the DOM before scrolling
     * because we update the cache right away. This workaround makes sure that the DOM is updated before scrolling
     */
    const scrollToBottomDelayed = (behavior) => {
        setTimeout(() => scrollToBottom(behavior), 100);
    };
    useEffect(() => {
        scrollToBottom('instant');
    }, []);
    useEffect(() => {
        // auto-scroll to the bottom (if at the "bottom") when streaming content.
        const scroller = scrollerRef.current;
        const inner = innerRef.current;
        if (!scroller || !inner) {
            return () => { };
        }
        const callback = () => {
            if (!autoscroll.current) {
                return;
            }
            scrollToBottom('smooth');
        };
        const observer = new ResizeObserver(
        // throttle to make it less jerky in firefox and safari.
        /chrome/i.test(navigator.userAgent) ? callback : lodashThrottle(callback, 200));
        observer.observe(inner, { box: 'border-box' });
        return () => {
            observer.unobserve(inner);
        };
    }, []);
    return (<ChatWrapperProvider scrollToBottom={scrollToBottomDelayed}>
      <Container className={className}>
        <ChatContainer ref={scrollerRef} onScroll={handleScroll}>
          <div ref={innerRef}>{chat}</div>
          <InputContainer>{input}</InputContainer>
        </ChatContainer>
      </Container>
    </ChatWrapperProvider>);
}
