var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, isApolloError } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { ChatMessageRole, ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { ChatWithDocumentDocument } from './mutation.gql';
import { useGetAssistantCredits } from '../useGetAssistantCredits';
import { DocumentAssistantChatErrorSubcodes } from './types';
export function useChatWithDocument() {
    const { addToast } = useToast();
    const { refetch: refetchCredits } = useGetAssistantCredits();
    const [mutate, state] = useMutation(ChatWithDocumentDocument);
    const handleError = (mutationError) => {
        var _a;
        if (isApolloError(mutationError)) {
            const subcode = (_a = mutationError.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode === DocumentAssistantChatErrorSubcodes.NoCredit) {
                refetchCredits();
                return addToast({
                    appearance: 'danger',
                    title: t({
                        message: 'No assistant credits left',
                        comment: 'Message for when the company ran out of assistant credits',
                    }),
                });
            }
        }
        return addToast({
            appearance: 'danger',
            title: t({
                message: 'Could not send message',
                comment: 'Message for when the document assistant could not sent the message',
            }),
        });
    };
    const handler = (_a) => __awaiter(this, [_a], void 0, function* ({ data, selection, type, chatId, message }) {
        const userMessage = {
            __typename: 'ChatMessage',
            id: `${chatId}-temp-user-message`,
            type,
            role: ChatMessageRole.User,
            content: message || null,
            status: ChatMessageStatus.Resolved,
            streamToken: null,
            feedbackScore: null,
            createdAt: new Date().toString(),
        };
        const assistantMessage = {
            __typename: 'ChatMessage',
            id: `${chatId}-temp-assistant-message`,
            type,
            role: ChatMessageRole.Assistant,
            content: null,
            status: ChatMessageStatus.Pending,
            streamToken: null,
            feedbackScore: null,
            createdAt: new Date().toString(),
        };
        try {
            const response = yield mutate({
                variables: { data, selection, type, chatId, message },
                optimisticResponse: {
                    lastChat: {
                        __typename: 'DocumentChat',
                        messages: [assistantMessage, userMessage],
                        id: chatId,
                        resolved: false,
                        resolutionStatus: null,
                    },
                },
            });
            return response;
        }
        catch (error) {
            handleError(error);
            return undefined;
        }
    });
    return [handler, state];
}
