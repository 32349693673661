import { gql } from '@apollo/client';
export const query = gql `
  query getFileUrl($documentId: ID!, $fileName: String!) {
    fileSystemDocument(id: $documentId) {
      files {
        id
        mimeType
        signed(name: $fileName) {
          url
        }
      }
    }
  }
`;
