var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobile } from 'shared/domains/common-ui';
import { StyledLink, Text } from './styled';
export function Exit(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    const isMobile = useMobile();
    return (<Button as={StyledLink} appearance="ghost" {...rest}>
      <Falcon icon="chevron-left" $size={16}/>
      {!isMobile && <Text>{children}</Text>}
    </Button>);
}
