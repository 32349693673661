import { Checkbox } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
import { AclAction } from 'shared/domains/apollo/generated/types';
import { useRole } from '../../hooks/useRole';
import { InputContainer } from './styled';
export function CheckboxACL(props) {
    var _a, _b;
    const { id, userId, parentAcl, isSelf } = props;
    const { isSubmitting } = useFormikContext();
    const [field, , { setValue }] = useField('userAcls');
    const userRole = useRole(id);
    const isOwner = userRole.is() === 'owner';
    const nonEditable = parentAcl === null || parentAcl === void 0 ? void 0 : parentAcl.some(user => user.userId === userId && user.actions.some(action => action !== AclAction.Traverse));
    const disabled = isSubmitting || isSelf || isOwner || nonEditable || userRole.state.loading || !field.value;
    const selected = (_b = (_a = field.value) === null || _a === void 0 ? void 0 : _a.some(user => user.userId === userId && user.actions.some(action => action !== AclAction.Traverse))) !== null && _b !== void 0 ? _b : false;
    const checked = isSelf || isOwner || nonEditable || selected;
    if (!userId) {
        return null;
    }
    const handleChange = (event) => {
        var _a;
        const currentValues = (_a = field.value) !== null && _a !== void 0 ? _a : [];
        if (!event.target.checked) {
            setValue(currentValues.filter(user => user.userId !== userId));
            return;
        }
        const aclAlreadyExist = currentValues.some(userAcl => userAcl.userId === userId);
        if (aclAlreadyExist) {
            const newValue = currentValues.map(userAcl => {
                if (userAcl.userId !== userId) {
                    return userAcl;
                }
                return Object.assign(Object.assign({}, userAcl), { actions: [AclAction.Read, AclAction.Write, AclAction.Delete] });
            });
            setValue(newValue);
        }
        if (aclAlreadyExist === false) {
            setValue([
                ...currentValues,
                {
                    userId,
                    actions: [AclAction.Read, AclAction.Write, AclAction.Delete],
                },
            ]);
        }
    };
    return (<InputContainer>
      <Checkbox name={field.name} disabled={disabled} checked={checked} onChange={handleChange}/>
    </InputContainer>);
}
