import { Box } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { XAxis, YAxis, ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import { constructSearchURL } from 'app/domains/global-search';
import { CartesianGrid } from '../CartesianGrid';
import { EmptyVisualization } from '../EmptyVisualization';
import { TooltipContent } from '../TooltipContent';
import { Unsupported } from '../Unsupported';
import { VisualizationMeta } from '../VisualizationMeta';
import { MIN_RECHART_HEIGHT, RECHARTS_FONT_SIZE, TOOLTIP_CURSOR_FILL } from '../constants';
import { getDateHistogramDiff } from '../getDateHistogramDiff';
import { getTheme } from '../getTheme';
import { transformAggregation } from '../transformData';
import { bucketTypeGuard } from '../typeGuards';
export function LineChart(props) {
    const { data, totalHitsCount, aggregations, colorScheme, filters } = props;
    const history = useHistory();
    const [dataPoint] = data || [];
    const [aggregation] = aggregations || [];
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const { entries, keys, missing, count } = transformAggregation(dataPoint, aggregation);
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    if (!dataPoint || !keys.length) {
        return <EmptyVisualization />;
    }
    const handleClick = (state) => {
        const { activePayload } = state;
        const { payload } = (activePayload === null || activePayload === void 0 ? void 0 : activePayload[0].payload) || {};
        const url = constructSearchURL({
            where: Object.assign(Object.assign({}, filters), payload.filter),
        });
        history.push(url);
    };
    const { sumOtherCount } = dataPoint;
    const diffValue = getDateHistogramDiff(dataPoint);
    const theme = getTheme(colorScheme);
    const xTickValues = [0, ...new Set(entries.map(entry => entry.total))].sort((a, b) => a - b);
    const topPadding = 10;
    const bottomPadding = 10;
    return (<Box flexDirection="column" width="100%" height="100%">
      <VisualizationMeta count={count} missing={missing} sumOtherCount={sumOtherCount || 0} totalHitsCount={totalHitsCount} diffValue={diffValue} aggregation={aggregation}/>
      <ResponsiveContainer minHeight={MIN_RECHART_HEIGHT} width="100%">
        <AreaChart data={entries} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} onClick={handleClick}>
          <Tooltip animationDuration={200} cursor={{ fill: TOOLTIP_CURSOR_FILL }} content={<TooltipContent keys={keys}/>}/>
          <XAxis dataKey="name" tickLine={false} axisLine={false} tick={{ fontSize: RECHARTS_FONT_SIZE, dy: 4 }} interval="preserveStartEnd" minTickGap={64}/>
          <YAxis axisLine={false} tickLine={false} tick={{ fontSize: RECHARTS_FONT_SIZE }} ticks={xTickValues} tickFormatter={(v, i) => (i === 0 || i === xTickValues.length - 1 ? v : '')} interval={0} allowDecimals={false} padding={{ bottom: bottomPadding, top: topPadding }} width={30} scale="linear"/>

          <linearGradient id="linechart-bg" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme('single')} stopOpacity={0.1}/>
            <stop offset="95%" stopColor={theme('single')} stopOpacity={0}/>
          </linearGradient>

          <CartesianGrid topPadding={topPadding} bottomPadding={bottomPadding}/>

          {keys.map(key => (<Area type="monotone" dataKey={key} // NOTE: Should this just be total?
         stroke={theme('line')} strokeWidth={2} strokeLinecap="round" fill="url(#linechart-bg)"/>))}
        </AreaChart>
      </ResponsiveContainer>
    </Box>);
}
