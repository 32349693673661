import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAssistant } from 'app/domains/assistant';
import { ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { Message } from 'shared/domains/common-ui';
import { CustomTable } from './CustomTable';
import { useClipboard } from './useClipboard';
import { useFeedback } from './useFeedback';
export function AssistantMessage(props) {
    const { message, status } = props;
    const { isLatestMessage, markdown, feedbackScore } = message;
    const { isStreaming, fuzzySearchTextNodes } = useAssistant();
    const { copyToClipboard } = useClipboard();
    const { likeAction, dislikeAction, feedbackDialog } = useFeedback({
        commentId: message.id,
        feedbackScore,
    });
    const loading = isLatestMessage && !markdown;
    const streaming = isLatestMessage && isStreaming;
    const handleCopy = () => copyToClipboard(message.markdown);
    const actions = [
        {
            icon: <Falcon icon="copy"/>,
            tooltip: t({
                comment: 'document assistant - assistant reply action - copy',
                message: 'Copy',
            }),
            disabled: !message.markdown,
            onClick: handleCopy,
        },
        dislikeAction,
        likeAction,
    ];
    const getFeedbackProps = (score) => {
        if (score === null || score === undefined || score === 0) {
            return undefined;
        }
        if (score > 0) {
            return {
                icon: <Falcon icon="thumbs-up"/>,
                tooltip: t({
                    comment: 'Document assistant - assistant reply positive feedback - aria label (screen reader)',
                    message: 'You thought this answer was helpful',
                }),
            };
        }
        return {
            icon: <Falcon icon="thumbs-down"/>,
            tooltip: t({
                comment: 'Document assistant - assistant reply negative feedback - aria label (screen reader)',
                message: 'You thought this answer was unhelpful',
            }),
        };
    };
    return (<Fragment>
      <Message fullWidth align="left" appearance="secondary" avatar={{ icon: <Falcon icon="sparkles"/>, appearance: 'ai' }} message={message} loading={loading} streaming={streaming} showActionsDefault={isLatestMessage} actions={status === ChatMessageStatus.Resolved ? actions : []} onFootnoteClick={fuzzySearchTextNodes} components={[{ tagName: 'table', component: CustomTable }]} badge={getFeedbackProps(feedbackScore)}/>
      {feedbackDialog}
    </Fragment>);
}
