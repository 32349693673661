var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { AssistantContext, AssistantViewType } from 'app/domains/assistant';
import { ChatMessageRole, ChatMessageStatus } from 'shared/domains/apollo/generated/types';
import { useDocumentDrawer } from 'shared/domains/documents';
import { Context } from './Context';
import { useAddChatComment } from './useAddChatComment';
import { useCreateChat } from './useCreateChat';
import { useDeleteChat } from './useDeleteChat';
import { useGetAssistantCredits } from './useGetAssistantCredits';
import { useGetChats } from './useGetChats';
import { useCancelDocumentChatMessage } from '../../hooks/useCancelDocumentChatMessage';
export function Provider(props) {
    const { children, fuzzySearchTextNodes, resetFuzzySearch, documentSelection } = props;
    const { collapse, expanded, openDrawer, closeDrawer, isDrawerOpen } = useDocumentDrawer();
    const { addToast } = useToast();
    const [assistantContext, setAssistantContext] = useState(AssistantContext.Document);
    const [view, setView] = useState(AssistantViewType.Dashboard);
    const updateView = (newView) => {
        if (newView === AssistantViewType.Dashboard && expanded) {
            collapse();
        }
        setView(newView);
    };
    const [selectedText, setSelectedText] = useState('');
    const isAssistantDrawerOpen = isDrawerOpen('app:assistant');
    const openAssistantDrawer = useCallback((context) => {
        openDrawer('app:assistant');
        setAssistantContext(context);
    }, [openDrawer]);
    useEffect(() => {
        if (documentSelection) {
            const currentSelection = documentSelection.value;
            if (currentSelection) {
                if (!isAssistantDrawerOpen) {
                    openAssistantDrawer(AssistantContext.Selection);
                }
                else {
                    setAssistantContext(AssistantContext.Selection);
                }
                setSelectedText(currentSelection !== null && currentSelection !== void 0 ? currentSelection : '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentSelection === null || documentSelection === void 0 ? void 0 : documentSelection.value]);
    const closeAssistantDrawer = () => {
        closeDrawer('app:assistant');
        setSelectedText('');
        if (documentSelection === null || documentSelection === void 0 ? void 0 : documentSelection.reset) {
            documentSelection.reset();
        }
    };
    const changeContext = (context) => {
        if (context !== AssistantContext.Selection && (documentSelection === null || documentSelection === void 0 ? void 0 : documentSelection.reset)) {
            documentSelection.reset();
        }
        setAssistantContext(context);
    };
    const { createChat, loading: creatingChat } = useCreateChat();
    const { addChatComment, streamingResponse, sendingComment } = useAddChatComment();
    const { deleteChat, loading: deletingChat } = useDeleteChat();
    const { chats, loading: loadingChats, error: chatsError } = useGetChats();
    const { availableCredits, loading: loadingCredits } = useGetAssistantCredits();
    const { cancel, loading: isCancellingStreaming } = useCancelDocumentChatMessage();
    const [activeChatId, setActiveChatId] = useState(null);
    const activeChat = chats.find(chat => chat.id === activeChatId);
    const initiating = loadingChats || loadingCredits;
    const initiatingError = Boolean(chatsError);
    const hasCredits = availableCredits > 0;
    const isStreaming = streamingResponse;
    const isPendingAssistantMessage = useMemo(() => {
        if (!activeChat) {
            return false;
        }
        const lastMessage = activeChat.messages.at(-1);
        return ((lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.role) === ChatMessageRole.Assistant &&
            (lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.status) === ChatMessageStatus.Pending);
    }, [activeChat]);
    const cannotSubmitUserMessage = creatingChat ||
        deletingChat ||
        sendingComment ||
        streamingResponse ||
        isPendingAssistantMessage;
    const goToChat = (chatId) => {
        setActiveChatId(chatId);
        updateView(AssistantViewType.Chat);
    };
    const goToDashboard = () => {
        setActiveChatId(null);
        updateView(AssistantViewType.Dashboard);
    };
    const goToConversations = () => {
        setActiveChatId(null);
        updateView(AssistantViewType.Conversations);
    };
    const createNewChat = (input) => __awaiter(this, void 0, void 0, function* () {
        try {
            const chatId = yield createChat();
            if (!chatId) {
                throw new Error();
            }
            addChatComment({
                chatId,
                input: Object.assign(Object.assign({}, input), { documentSelection: selectedText }),
            });
            goToChat(chatId);
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'We could not start the conversation. Try again',
                    comment: 'Document assistant - error when trying to create a new conversation',
                }),
            });
        }
    });
    const addComment = (chatId, input) => {
        addChatComment({
            chatId,
            input: Object.assign(Object.assign({}, input), { documentSelection: selectedText }),
        });
    };
    const cancelStreaming = () => {
        if (!activeChat || !streamingResponse) {
            return;
        }
        const latestMessage = activeChat.messages.at(-1);
        if (latestMessage && latestMessage.role === ChatMessageRole.Assistant) {
            cancel(latestMessage.id);
        }
    };
    const value = {
        view,
        chats,
        initiating,
        initiatingError,
        creatingChat,
        deletingChat,
        hasCredits,
        addComment,
        deleteChat,
        createNewChat,
        activeChat,
        cannotSubmitUserMessage,
        cancelStreaming,
        isStreaming,
        isCancellingStreaming,
        isPendingAssistantMessage,
        goToChat,
        goToDashboard,
        goToConversations,
        changeContext,
        context: assistantContext,
        openAssistantDrawer,
        closeAssistantDrawer,
        fuzzySearchTextNodes,
        resetFuzzySearch,
        selectedText,
        documentSelection,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
