import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { usePlaiDrawer } from 'app/domains/documents';
import { usePlai } from '../../../PlaiProvider';
export function ChatActions(props) {
    const { feedback, isStreaming, onRewriteClick } = props;
    const { acceptFeedback, feedbackHighlightsData } = usePlai();
    const { documentHighlights = [], loading } = feedbackHighlightsData || {};
    const { closeFeedbackChat } = usePlaiDrawer();
    const isRewriteAvailable = documentHighlights.length > 0 && !loading;
    const handleResolve = () => {
        acceptFeedback(feedback.feedback.id);
        closeFeedbackChat();
    };
    const buttonProps = {
        appearance: 'ghost',
        size: 'small',
        disabled: isStreaming,
    };
    return (<Fragment>
      {isRewriteAvailable && (<Tooltip title={t({
                comment: 'Review chat - user actions - rewrite button tooltip',
                message: 'Rewrite the clause',
            })}>
          <Button {...buttonProps} onClick={onRewriteClick}>
            <Falcon icon="pen-to-square"/>
            <Trans comment="Review chat - user actions - rewrite button label">Rewrite</Trans>
          </Button>
        </Tooltip>)}

      <Tooltip title={t({
            comment: 'Review chat - user actions - resolve button tooltip',
            message: 'Resolve the issue',
        })}>
        <Button {...buttonProps} onClick={handleResolve}>
          <Falcon icon="check"/>
          <Trans comment="Review chat - user actions - resolve button label">Resolve</Trans>
        </Button>
      </Tooltip>
    </Fragment>);
}
