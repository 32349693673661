var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { isApolloError } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Dialog } from './Dialog';
import { useDislikeChatMessage } from './useDislikeChatMessage';
import { useLikeChatMessage } from './useLikeChatMessage';
const FEEDBACK_ALREADY_GIVEN_SUBCODE = 'ERROR_CHAT_MESSAGE_APPRAISAL_ALREADY_GIVEN';
export const useFeedback = (props) => {
    const { commentId, feedbackScore } = props;
    const { addToast } = useToast();
    const [feedback, setFeedback] = useState();
    const [dislike, { loading: sendingDislike }] = useDislikeChatMessage();
    const [like, { loading: sendingLike }] = useLikeChatMessage();
    const [error, setError] = useState(false);
    const loading = sendingDislike || sendingLike;
    const handleLikeClick = () => setFeedback('like');
    const handleDislikeClick = () => setFeedback('dislike');
    const hideModal = () => setFeedback(undefined);
    const handleClose = () => {
        setError(false);
        hideModal();
    };
    const handleFeedbackSent = () => {
        hideModal();
        addToast({
            appearance: 'success',
            title: t({
                message: 'Feedback sent. Thank you!',
                comment: 'Document assistant - feedback dialog - successful toast title',
            }),
        });
    };
    const handleError = (mutationError) => {
        var _a;
        setError(true);
        if (isApolloError(mutationError)) {
            const subcode = (_a = mutationError.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode === FEEDBACK_ALREADY_GIVEN_SUBCODE) {
                return addToast({
                    appearance: 'success',
                    title: t({
                        message: 'We already had your feedback for this response. Thank you!',
                        comment: 'Document assistant - feedback dialog - toast title when the feedback had already been given but the user submitted again',
                    }),
                });
            }
        }
        return addToast({
            appearance: 'danger',
            title: t({
                message: 'Could not send feedback',
                comment: 'Document assistant - feedback dialog - toast title when the feedback failed to submit',
            }),
        });
    };
    const handleLike = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setError(false);
        return like(values).then(handleFeedbackSent).catch(handleError);
    });
    const handleDislike = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setError(false);
        return dislike(values).then(handleFeedbackSent).catch(handleError);
    });
    return {
        likeAction: {
            icon: <Falcon icon="thumbs-up"/>,
            tooltip: t({
                comment: 'Document assistant - assistant reply feedback - aria label (screen reader) - like answer',
                message: 'Like answer',
            }),
            onClick: handleLikeClick,
            disabled: !!feedbackScore || loading,
        },
        dislikeAction: {
            icon: <Falcon icon="thumbs-down"/>,
            tooltip: t({
                comment: 'Document assistant - assistant reply feedback - aria label (screen reader) - dislike answer',
                message: 'Dislike answer',
            }),
            onClick: handleDislikeClick,
            disabled: !!feedbackScore || loading,
        },
        feedbackDialog: (<Dialog commentId={commentId} feedback={feedback} onClose={handleClose} onDislike={handleDislike} onLike={handleLike} mutationError={error}/>),
    };
};
