var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { DeleteDocumentChatDocument } from './DeleteDocumentChat.gql';
export function useDeleteChat() {
    const [mutate, { loading }] = useMutation(DeleteDocumentChatDocument);
    const deleteChat = (chatId) => __awaiter(this, void 0, void 0, function* () {
        yield mutate({
            variables: { chatId },
            update(cache, { data }) {
                const id = data === null || data === void 0 ? void 0 : data.deleteDocumentChat.id;
                // eslint-disable-next-line no-underscore-dangle
                const typename = data === null || data === void 0 ? void 0 : data.deleteDocumentChat.__typename;
                if (id) {
                    cache.evict({ id: `${typename}:${id}` });
                    cache.gc();
                }
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    });
    return { deleteChat, loading };
}
