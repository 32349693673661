import { t } from '@lingui/macro';
import { Checkbox } from '@pocketlaw/tetris';
import { useDocumentApproversWithStatus } from 'app/domains/esigning/hooks/useDocumentApproversWithStatus';
import { SectionList, SectionListItem } from '../../components';
export function Approvers(props) {
    const { selectedApproverUserIds, onToggleApprover } = props;
    const { approvers } = useDocumentApproversWithStatus();
    return (<SectionList title={t({ comment: 'Document approvers', message: 'Approvers' })}>
      {approvers.map(approver => (<SectionListItem key={approver.userId} primary={approver.name} secondary={approver.email} endElement={<Checkbox $size="large" checked={selectedApproverUserIds.includes(approver.userId)} onChange={() => onToggleApprover(approver.userId)}/>}/>))}
    </SectionList>);
}
