import { useState, Fragment } from 'react';
import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PromptLibraryProvider, PromptLibraryModal } from 'app/domains/prompt-library';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { PopButton } from '../PopButton';
export function PromptLibraryButton(props) {
    const { disabled, onSelectPrompt } = props;
    const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
    const isPromptLibraryAvailable = useFeatureFlag('feature_prompt_library');
    const openPromptLibrary = () => setPromptLibraryOpen(true);
    const closePromptLibrary = () => setPromptLibraryOpen(false);
    const selectPrompt = (prompt) => {
        onSelectPrompt(prompt);
        closePromptLibrary();
    };
    return (<Fragment>
      <PopButton disabled={disabled} icon={<Falcon icon="book-bookmark"/>} label={t({
            comment: 'Assistant chat dashboard - prompt library button label',
            message: 'Prompt library',
        })} onClick={openPromptLibrary}/>
      {isPromptLibraryAvailable && promptLibraryOpen && (<PromptLibraryProvider defaultView="library" onSelect={selectPrompt} onClose={closePromptLibrary}>
          <PromptLibraryModal />
        </PromptLibraryProvider>)}
    </Fragment>);
}
