import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { List } from '@pocketlaw/tetris';
import { isThisMonth, isThisWeek } from 'date-fns';
import { EventListItem } from './EventListItem';
import { Container, StyledListHeader } from './styled';
const MOCKED_DATA = [
    {
        id: 'id.1',
        userName: 'Klara',
        eventType: 'cancel',
        createdAt: 'Feb 6 2024 00:00:00',
        message: 'Thank you.',
    },
    {
        id: 'id.2',
        userName: 'Eric Johnson',
        eventType: 'comment',
        createdAt: 'Feb 5 2024 00:00:00',
        message: 'Thank you.',
    },
    {
        id: 'id.3',
        userName: 'Johan',
        eventType: 'reject',
        createdAt: 'Feb 5 2024 00:00:00',
        message: 'We need another clause in this agreement.',
    },
    {
        id: 'id.10',
        userName: 'Johan',
        eventType: 'reject',
        createdAt: 'Feb 2 2024 00:00:00',
        message: 'We need another clause in this agreement.',
    },
    {
        id: 'id.4',
        userName: 'Ricky Hansen',
        eventType: 'approve',
        createdAt: 'Feb 1 2024 00:00:00',
        message: 'Everything looks good to me.',
    },
    {
        id: 'id.5',
        userName: 'Eric Johnson',
        approverUserNames: ['Ricky Hansen'],
        eventType: 'request',
        createdAt: 'Jan 9 2024 00:00:00',
        message: 'Would you mind have a look at this, please?',
    },
    {
        id: 'id.999',
        userName: 'Eric Johnson',
        approverUserNames: ['Dan', 'Max', 'Mattias'],
        eventType: 'request',
        createdAt: 'Jan 9 2024 00:00:00',
        message: 'Would you mind have a look at this, please?',
    },
    {
        id: 'id.6',
        userName: 'Eric Johnson',
        eventType: 'comment',
        createdAt: 'Dec 24 2023 00:00:00',
        message: 'Thank you.',
    },
    {
        id: 'id.7',
        userName: 'Johan',
        eventType: 'reject',
        createdAt: 'Nov 6 2023 00:00:00',
        message: 'We need another clause in this agreement.',
    },
];
export function ActivityTab() {
    const weeksEvents = MOCKED_DATA.filter(({ createdAt }) => isThisWeek(createdAt));
    const monthsEvents = MOCKED_DATA.filter(({ createdAt }) => !isThisWeek(createdAt) && isThisMonth(createdAt));
    const pastMonthsEvents = MOCKED_DATA.filter(({ createdAt }) => !isThisWeek(createdAt) && !isThisMonth(createdAt));
    return (<Container>
      <List>
        {weeksEvents.length > 0 && (<Fragment>
            <StyledListHeader>
              <Trans comment="Approval Drawer - History tab - Week section - title">
                This week
              </Trans>
            </StyledListHeader>
            {weeksEvents.map(data => (<EventListItem key={data.id} {...data}/>))}
          </Fragment>)}
        {monthsEvents.length > 0 && (<Fragment>
            <StyledListHeader>
              <Trans comment="Approval Drawer - History tab - Month section - title">
                This month
              </Trans>
            </StyledListHeader>
            {monthsEvents.map(data => (<EventListItem key={data.id} {...data}/>))}
          </Fragment>)}
        {pastMonthsEvents.length > 0 && (<Fragment>
            <StyledListHeader>
              <Trans comment="Approval Drawer - History tab - All time section - title">
                All time
              </Trans>
            </StyledListHeader>
            {pastMonthsEvents.map(data => (<EventListItem key={data.id} {...data}/>))}
          </Fragment>)}
      </List>
    </Container>);
}
