var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { ApprovalRequestApproverStatus } from 'shared/domains/apollo/generated/types';
import { lastApprovalRequestQuery } from './query';
export const useLastApprovalRequest = (documentId) => {
    var _a;
    const _b = useQuery(lastApprovalRequestQuery, {
        variables: { documentId },
    }), { data } = _b, rest = __rest(_b, ["data"]);
    const lastApprovalRequest = ((_a = data === null || data === void 0 ? void 0 : data.fileSystemDocument) === null || _a === void 0 ? void 0 : _a.lastApprovalRequest) || null;
    const requester = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.requestedByUser;
    const rejecter = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.approvers.find(approver => (approver === null || approver === void 0 ? void 0 : approver.status) === ApprovalRequestApproverStatus.Rejected);
    return Object.assign(Object.assign({}, rest), { lastApprovalRequest, requester, rejecter });
};
