import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
const sizeMap = {
    small: pxToRem(32),
    medium: pxToRem(40),
    large: pxToRem(48),
};
export const TabsButtonText = styled.span `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TabButton = styled.button `
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  padding: 0 ${themeGet('spacing.md')};

  line-height: 1;
  color: ${themeGet('colors.text.100')};
  font-size: ${themeGet('fontSize.small')};
  font-weight: ${themeGet('fontWeight.semibold')};

  border-radius: ${themeGet('borderRadius.sm')};
  box-shadow: none;
  background-color: transparent;
  transition: all 175ms ease-in-out;

  &:hover {
    ${props => !props.$active &&
    `
      cursor: pointer;
      color: ${themeGet('colors.text.300')};
      background-color: ${themeGet('colors.gray.100')};
    `}
  }

  ${props => props.$active &&
    `
    color: ${themeGet('colors.text.300')};
    background-color: ${themeGet('colors.background.50')};
    box-shadow: ${themeGet('dropShadow.100')};
  `}
`;
export const ButtonsContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  height: ${pxToRem(40)};
  gap: ${themeGet('spacing.sm')};
  padding: ${themeGet('spacing.sm')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};

  ${props => props.$stretch &&
    `
    width: 100%;
  `}

  ${props => props.$size &&
    `
    height: ${sizeMap[props.$size]};
  `}
`;
