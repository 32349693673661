var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { t } from '@lingui/macro';
import { TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentStatusChip } from 'app/domains/documents';
import { isDocument } from './utils';
export function DocumentStatusCell(props) {
    const { status, documentId, entityId, typename, entityType } = props, rest = __rest(props, ["status", "documentId", "entityId", "typename", "entityType"]);
    if (!isDocument(entityType)) {
        return (<TableBodyCell {...rest}>
        <TableCellEmpty />
      </TableBodyCell>);
    }
    return (<TableBodyCell {...rest}>
      <DocumentStatusChip status={status} documentId={documentId} cacheId={entityId} typename={typename} type={entityType} label={t({ message: 'Add', comment: 'Global search: Label for document status' })} icon={<Falcon icon="plus"/>} variant="outline"/>
    </TableBodyCell>);
}
