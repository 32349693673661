import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { Tooltip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignPartyItemAccordion } from 'app/domains/esigning/components/ESignPartyItemAccordion';
import { SectionListItem } from 'app/domains/esigning/components/components';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { getSignatoryNameByFormat } from 'app/domains/esigning/utils';
import { PartyStatusLabel } from './PartyStatusLabel';
import { SignatoryStatusBadge } from './SignatoryStatusBadge';
import { usePartyStatus } from './usePartyStatus';
import { StyledIconButton } from './styled';
export function ESignPartyItem(props) {
    const { signatory, signingStatus, endElement, groupMessage, hideAvatar = false } = props;
    const { properties } = useEsigningProviderMetadata();
    const { nameFormat: format } = properties;
    const partyStatus = usePartyStatus(signingStatus);
    const { email, firstName, lastName, label, title, isSigning, authenticationMode, mobile, message, name = '', } = signatory;
    const signatoryName = getSignatoryNameByFormat({ format, name, firstName, lastName });
    const personalMessageElement = (<Tooltip side="left" title={t({
            comment: 'Tooltip for signing party with a personal message added',
            message: 'Personal message added',
        })}>
      <StyledIconButton $round size="small" appearance="ghost">
        <Falcon icon="message-lines"/>
      </StyledIconButton>
    </Tooltip>);
    return (<SectionListItem primary={signatoryName} secondary={email} tertiary={partyStatus && <PartyStatusLabel {...partyStatus}/>} hideAvatar={hideAvatar} endElement={<Fragment>
          {signingStatus && <SignatoryStatusBadge status={signingStatus.status}/>}
          {message && personalMessageElement}
          {endElement}
        </Fragment>} accordionElement={<ESignPartyItemAccordion email={email} title={title} mobile={mobile} label={label} isSigning={isSigning} authenticationMode={authenticationMode} partyStatus={partyStatus} message={message} groupMessage={groupMessage}/>}/>);
}
