import { AvatarList, AvatarLetter, TableBodyCell, Tooltip } from '@pocketlaw/tetris';
import { AvatarsContainer } from './styled';
export function ApproversCell(props) {
    const { approvers } = props;
    const avatars = approvers.map(approver => ({
        id: approver.id,
        tooltip: { title: approver.name },
        component: <AvatarLetter>{approver.name[0]}</AvatarLetter>,
        appearance: 'cyan',
    }));
    const tooltip = approvers.map(approver => approver.name).join(', ');
    return (<TableBodyCell headers="approversCell">
      <Tooltip title={tooltip}>
        <AvatarsContainer>
          <AvatarList avatars={avatars}/>
        </AvatarsContainer>
      </Tooltip>
    </TableBodyCell>);
}
