import { useToggleTabs } from './useToggleTabs';
import { TabButton, TabsButtonText } from './styled';
export function ToggleTabsButton(props) {
    const { tabKey, children } = props;
    const { activeTab, setActiveTab } = useToggleTabs();
    const active = activeTab === tabKey;
    const handleClick = () => setActiveTab(tabKey);
    return (<TabButton $active={active} onClick={handleClick}>
      <TabsButtonText>{children}</TabsButtonText>
    </TabButton>);
}
