import { t } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
export function SidebarItemValue(props) {
    const { data } = props;
    const noValue = t({
        message: 'No data',
        comment: 'Document metadata: When no data is set, displayed for all fields.',
    });
    return (<Typography data-testid="metadata-value" $lineHeight="neutralized" $appearance={data ? '200' : '100'} $fontStyle={data ? 'normal' : 'italic'}>
      {data || noValue}
    </Typography>);
}
