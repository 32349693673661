import { Fragment } from 'react';
import { addMetadataToMessages, groupMessages } from './utils';
export function Chat(props) {
    const { messages, renderMessage } = props;
    const groupedMessages = addMetadataToMessages(groupMessages(messages));
    return (<Fragment>
      {groupedMessages.map((group, groupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={groupIndex}>
          {group.map(message => (<Fragment key={message.id}>{renderMessage(message)}</Fragment>))}
        </Fragment>))}
    </Fragment>);
}
