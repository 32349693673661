import { Fragment, useState } from 'react';
import { MenuBody, MenuFooter, MenuHeader, MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { ErrorAlert } from './components/ErrorAlert';
import { ItemList } from './components/ItemList';
import { Search } from './components/Search';
import { SubmitButton } from './components/SubmitButton';
import { useItemSelect } from './hooks/useItemSelect';
import { getTitles } from './utils/getTitles';
/**
 * use this component to list users and/or teams and be able to select and submit them
 */
export function UserAndTeamSelect(props) {
    const { users = [], teams = [], search, onSearch, onSubmit, submitButtonLabel, searching, } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedUsers, unselectedUsers, selectUser, unselectUser] = useItemSelect(users);
    const [selectedTeams, unselectedTeams, selectTeam, unselectTeam] = useItemSelect(teams);
    const { selectedItemsTitle, unselectedItemsTitle } = getTitles({
        // we need to also send the selected items (which is saved in local state)
        // otherwise if the search has filtered out all the regular "items" the array would be empty and the titles would be wrong
        users: [...selectedUsers, ...unselectedUsers],
        teams: [...selectedTeams, ...unselectedTeams],
        search,
    });
    const handleSubmit = () => {
        setLoading(true);
        setError(false);
        const selectedUserIds = selectedUsers.map(({ id }) => id);
        const selectedTeamIds = selectedTeams.map(({ id }) => id);
        onSubmit({ selectedUserIds, selectedTeamIds })
            .catch(() => {
            setError(true);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const hasSelectedItems = selectedUsers.length > 0 || selectedTeams.length > 0;
    const hasUnselectedItems = unselectedUsers.length > 0 || unselectedTeams.length > 0;
    return (<Fragment>
      <MenuHeader sticky>
        <Search search={search} onSearch={onSearch} users={users} teams={teams} loading={searching}/>
      </MenuHeader>
      <MenuBody>
        <MenuList>
          {error && <ErrorAlert />}
          {hasSelectedItems && (<Fragment>
              <MenuSubheader>{selectedItemsTitle}</MenuSubheader>
              <ItemList items={selectedTeams} type="team" checked onChange={unselectTeam}/>
              <ItemList items={selectedUsers} type="user" checked onChange={unselectUser}/>
            </Fragment>)}
          {hasUnselectedItems && (<Fragment>
              <MenuSubheader>{unselectedItemsTitle}</MenuSubheader>
              <ItemList items={unselectedTeams} type="team" checked={false} onChange={selectTeam}/>
              <ItemList items={unselectedUsers} type="user" checked={false} onChange={selectUser}/>
            </Fragment>)}
        </MenuList>
      </MenuBody>
      <MenuFooter sticky>
        <SubmitButton loading={loading} onClick={handleSubmit} disabled={!hasSelectedItems}>
          {submitButtonLabel}
        </SubmitButton>
      </MenuFooter>
    </Fragment>);
}
