var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useToast } from '@pocketlaw/tetris';
import { useSearchContext } from 'app/domains/global-search';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { cleanValue, downloadBlob, stringifyCellValue } from './utils';
import { GlobalSearchDocument } from '../../hooks/useSearchQuery/GlobalSearch.gql';
const DELIMITER = ';';
/**
 * A custom React hook for downloading CSV data from a global search.
 * This hook manages the state for downloading large datasets in batches,
 */
export function useDownloadCSV() {
    const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
    const [maxBatchIndex, setMaxBatchIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fetchSearch, { error }] = useLazyQuery(GlobalSearchDocument);
    const { values } = useSearchContext();
    const { i18n } = useLingui();
    const { addToast } = useToast();
    const { columns, query, filters, orderBy } = values;
    const visibleColumns = useMemo(() => columns.filter(col => col.active && col.id !== 'action'), [columns]);
    const download = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const limit = 500;
        let offset = 0;
        let csvContent = '';
        const headers = `${visibleColumns.map(column => `"${i18n._(column.label)}"`).join(DELIMITER)}\n`;
        csvContent += headers;
        try {
            setLoading(true);
            // eslint-disable-next-line no-constant-condition
            while (true) {
                setCurrentBatchIndex(prevIndex => prevIndex + 1);
                // eslint-disable-next-line no-await-in-loop
                const { data } = yield fetchSearch({
                    fetchPolicy: 'network-only',
                    variables: {
                        query,
                        where: Object.assign(Object.assign({}, filters), { entityType: {
                                in: [
                                    SearchEntityTypeEnum.DocumentComposed,
                                    SearchEntityTypeEnum.DocumentFreetext,
                                    SearchEntityTypeEnum.DocumentUploaded,
                                ],
                            } }),
                        orderBy,
                        limit,
                        offset,
                    },
                });
                if (!((_a = data === null || data === void 0 ? void 0 : data.search) === null || _a === void 0 ? void 0 : _a.results) || data.search.results.length === 0) {
                    break;
                }
                if (currentBatchIndex === 0) {
                    const totalHitsCount = data.search.totalHitsCount || 0;
                    setMaxBatchIndex(Math.ceil(totalHitsCount / limit));
                }
                const batchContent = data.search.results
                    .map(row => visibleColumns
                    .map(column => stringifyCellValue(row[column.id]))
                    .map(cleanValue)
                    .join(DELIMITER))
                    .join('\n');
                csvContent += `${batchContent}\n`;
                if (offset >= 10000 || data.search.results.length < limit) {
                    break;
                }
                offset += limit;
            }
            downloadBlob(csvContent);
            addToast({
                title: t({
                    message: 'Download complete',
                    comment: 'CSV download button: Download complete toast',
                }),
                appearance: 'success',
            });
        }
        catch (err) {
            addToast({
                title: t({
                    message: 'Download failed',
                    comment: 'CSV download button: Download failed toast',
                }),
                appearance: 'danger',
            });
        }
        finally {
            setLoading(false);
            setCurrentBatchIndex(0);
            setMaxBatchIndex(0);
        }
    }), [visibleColumns, i18n, addToast, fetchSearch, query, filters, orderBy, currentBatchIndex]);
    const progress = currentBatchIndex && maxBatchIndex ? Math.round((currentBatchIndex / maxBatchIndex) * 100) : 0;
    return { download, loading, error, progress };
}
