import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function getSearchEntityIcon(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentUploaded:
            return <Falcon icon="file-upload"/>;
        case SearchEntityTypeEnum.Draft:
            return <Falcon icon="file"/>;
        case SearchEntityTypeEnum.Task:
            return <Falcon icon="clipboard-list-check"/>;
        case SearchEntityTypeEnum.Template:
            return <Falcon icon="file-pen"/>;
        case SearchEntityTypeEnum.Folder:
            return <Falcon icon="folder"/>;
        case SearchEntityTypeEnum.User:
            return <Falcon icon="image-user"/>;
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        default:
            return <Falcon icon="file-alt"/>;
    }
}
