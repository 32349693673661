import { useCallback, useEffect, useRef, useState } from 'react';
import { useUnmount } from 'react-use';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { getEsigningProviderMetadataByProviderId } from 'app/domains/esigning/utils';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { useConnectEsigningProvider } from '../useConnectEsigningProvider';
import { usePollCompanyEsigningProviders } from '../usePollCompanyEsigningProviders';
const CONNECT_POPUP_INTERVAL_MS = 500;
export const useConnectProvider = (props) => {
    const { onConnectProviderError, onConnectProviderSuccess } = props;
    const { connectedProvider } = useEsigningProviders();
    const { data: accountData } = useAccount();
    const { companyId = '' } = accountData;
    const { mutate: connectEsigningProvider, error: connectProviderError } = useConnectEsigningProvider();
    const [connectingProvider, setConnectingProvider] = useState(false);
    const connectPopupTimerRef = useRef(null);
    const connectPopupWindowRef = useRef(null);
    const isConnected = Boolean(connectedProvider);
    const isExpired = Boolean(connectedProvider === null || connectedProvider === void 0 ? void 0 : connectedProvider.expired);
    usePollCompanyEsigningProviders(connectingProvider);
    const closePopupWindow = () => {
        if (connectPopupWindowRef.current) {
            connectPopupWindowRef.current.close();
            connectPopupWindowRef.current = null;
        }
    };
    const clearPopupTimer = () => {
        if (connectPopupTimerRef.current) {
            clearInterval(connectPopupTimerRef.current);
        }
    };
    const handleConnectPopupClose = useCallback(() => {
        setConnectingProvider(false);
        clearPopupTimer();
        closePopupWindow();
        if (isConnected) {
            onConnectProviderSuccess();
        }
        else {
            onConnectProviderError();
        }
    }, [isConnected, onConnectProviderError, onConnectProviderSuccess]);
    const checkConnectPopup = () => {
        var _a;
        if (connectPopupTimerRef.current && ((_a = connectPopupWindowRef.current) === null || _a === void 0 ? void 0 : _a.closed)) {
            handleConnectPopupClose();
        }
    };
    const openConnectPopup = (providerId) => {
        const url = new URL(`/connect/${providerId}`, process.env.E_SIGNING_SERVICE_URL);
        url.searchParams.append('company_id', companyId);
        connectPopupWindowRef.current = window.open(url, '_blank', 'popup');
        connectPopupTimerRef.current = setInterval(checkConnectPopup, CONNECT_POPUP_INTERVAL_MS);
    };
    const connectProvider = (providerId) => {
        const { properties } = getEsigningProviderMetadataByProviderId(providerId);
        if (properties.hasExternalAPI) {
            openConnectPopup(providerId);
        }
        else {
            connectEsigningProvider(providerId);
        }
        setConnectingProvider(true);
    };
    useEffect(() => {
        if (isConnected && !isExpired) {
            handleConnectPopupClose();
        }
    }, [connectingProvider, isConnected, isExpired, handleConnectPopupClose]);
    useUnmount(() => {
        clearPopupTimer();
        closePopupWindow();
    });
    return { connectingProvider, connectProvider, connectProviderError };
};
