import { t } from '@lingui/macro';
import { SearchAggregationsAllowedPropertiesForDateRangeAggregation as DateRangeAllowedProperties } from 'shared/domains/apollo/generated/types';
export function allowedPropertiesTranslations(key) {
    const translations = {
        [DateRangeAllowedProperties.CreatedAt]: t({
            message: 'Document created date',
            comment: 'Insights: Date Range allowed input: CreatedAt',
        }),
        [DateRangeAllowedProperties.MetadataDocumentDateSigned]: t({
            message: 'Signed date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentDateSigned',
        }),
        [DateRangeAllowedProperties.MetadataDocumentNoticeDate]: t({
            message: 'Notice date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentNoticeDate',
        }),
        [DateRangeAllowedProperties.MetadataDocumentProbationaryPeriodExpirationDate]: t({
            message: 'Probationary period expiration date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentProbationaryPeriodExpirationDate',
        }),
        [DateRangeAllowedProperties.MetadataDocumentStartDate]: t({
            message: 'Start date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentStartDate',
        }),
        [DateRangeAllowedProperties.MetadataDocumentTerminationDate]: t({
            message: 'Termination date',
            comment: 'Insights: Date Range allowed input: MetadataDocumentTerminationDate',
        }),
        [DateRangeAllowedProperties.UpdatedAt]: t({
            message: 'Document updated at',
            comment: 'Insights: Date Range allowed input: UpdatedAt',
        }),
    };
    return translations[key];
}
