import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function NoApproversAlert() {
    return (<Alert appearance="warning" title={t({
            comment: 'Document requires approval state - No approvers available - Warning label',
            message: 'No approvers available',
        })} message={t({
            comment: 'Document requires approval state - No approvers available - Warning message',
            message: 'It seems like there are no approvers available. You have to have at least one approver to send the document for approval. Update the approvers and try again.',
        })}/>);
}
