import { differenceInCalendarDays } from 'date-fns';
export var ChatDateGroup;
(function (ChatDateGroup) {
    ChatDateGroup["Today"] = "TODAY";
    ChatDateGroup["Yesterday"] = "YESTERDAY";
    ChatDateGroup["LastWeek"] = "LAST_WEEK";
    ChatDateGroup["LastMonth"] = "LAST_MONTH";
    ChatDateGroup["OverOneMonth"] = "OVER_ONE_MONTH";
})(ChatDateGroup || (ChatDateGroup = {}));
const sortGroupByLatestMessage = (a, b) => {
    var _a, _b;
    const aDate = new Date(((_a = a.messages.at(-1)) === null || _a === void 0 ? void 0 : _a.createdAt) || '');
    const bDate = new Date(((_b = b.messages.at(-1)) === null || _b === void 0 ? void 0 : _b.createdAt) || '');
    return bDate.getTime() - aDate.getTime();
};
export const groupChatsByDate = (chats) => {
    const today = new Date();
    const groupMap = {
        [ChatDateGroup.Today]: [],
        [ChatDateGroup.Yesterday]: [],
        [ChatDateGroup.LastWeek]: [],
        [ChatDateGroup.LastMonth]: [],
        [ChatDateGroup.OverOneMonth]: [],
    };
    const map = chats.reduce((acc, chat) => {
        const lastChatMessage = chat.messages.at(-1);
        if (!(lastChatMessage === null || lastChatMessage === void 0 ? void 0 : lastChatMessage.createdAt)) {
            return acc;
        }
        const createdAt = new Date(lastChatMessage.createdAt);
        const diff = differenceInCalendarDays(today, createdAt);
        if (diff === 0) {
            acc[ChatDateGroup.Today].push(chat);
        }
        else if (diff === 1) {
            acc[ChatDateGroup.Yesterday].push(chat);
        }
        else if (diff <= 7) {
            acc[ChatDateGroup.LastWeek].push(chat);
        }
        else if (diff <= 30) {
            acc[ChatDateGroup.LastMonth].push(chat);
        }
        else {
            acc[ChatDateGroup.OverOneMonth].push(chat);
        }
        return acc;
    }, groupMap);
    const entries = Object.entries(map);
    const filteredEntries = entries.filter(([, items]) => items.length > 0);
    const sortedChatGroups = filteredEntries.map(([group, items]) => ({
        key: group,
        items: items.sort(sortGroupByLatestMessage),
    }));
    return sortedChatGroups;
};
