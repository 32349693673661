import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Chip, MenuList, MenuItem, MenuBody, MenuItemText } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSetDocumentStatus } from 'app/domains/documents';
import { DocumentType, FileSystemDocumentStatus, FileSystemDocumentStatusExplicitEvents, } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { getAppearance, mapSearchType } from './utils';
import { getDocumentStatusTranslations } from '../../utils/getDocumentStatusTranslations';
export function DocumentStatusChip(props) {
    const { status, documentId, cacheId, typename, type, size = 'medium', label, secondary, variant, appearance, icon, } = props;
    const { setStatus, loading } = useSetDocumentStatus();
    const lingui = useLingui();
    const translatedStatus = getDocumentStatusTranslations(status);
    const chipAppearance = getAppearance(status);
    const handleSetNewState = (event) => () => setStatus([
        {
            event,
            documentId,
            cacheId,
            typename,
        },
    ]);
    const isUnknown = status === FileSystemDocumentStatus.Unknown;
    const isUploaded = mapSearchType(type) === DocumentType.Uploaded;
    if (isUnknown && isUploaded) {
        return (<ChipDropdown size={size} variant={variant} appearance={appearance} icon={icon} loading={loading} label={label} secondary={secondary} dropdownContent={<MenuBody>
            <MenuList>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetAgreed)}>
                <MenuItemText primary={t({ message: 'Set as agreed', comment: 'Set document status - Title' })} secondary={t({
                    message: 'Description for set as agreed',
                    comment: 'Set document status - Description',
                })}/>
              </MenuItem>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetSigned)}>
                <MenuItemText primary={t({ message: 'Set as signed', comment: 'Set document status - Title' })} secondary={t({
                    message: 'Description for set as signed',
                    comment: 'Set document status - Description',
                })}/>
              </MenuItem>
              <MenuItem onClick={handleSetNewState(FileSystemDocumentStatusExplicitEvents.ExplicitlySetDraft)}>
                <MenuItemText primary={t({ message: 'Set as draft', comment: 'Set document status - Title' })} secondary={t({
                    message: 'Description for set as draft',
                    comment: 'Set document status - Description',
                })}/>
              </MenuItem>
            </MenuList>
          </MenuBody>}/>);
    }
    return (<Chip icon={<Falcon icon="lock"/>} label={lingui._(translatedStatus)} appearance={chipAppearance} variant="solid" size={size}/>);
}
