import { t, Trans } from '@lingui/macro';
import { CardHeader, Button, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { useTasks } from '../TasksProvider';
import { ButtonWrapper } from './styled';
export function WidgetHeader(props) {
    const { title, hideLink } = props;
    const { displayAdd, setDisplayAdd } = useTasks();
    return (<CardHeader title={title} action={<ButtonWrapper>
          {!hideLink && (<Button as={Link} appearance="ghost" to="/tasks" size="xs">
              <Trans comment="Link to task table page">Show all</Trans>
            </Button>)}
          <IconButton $round size="xs" aria-label={t `Add task`} appearance="ghost" onClick={() => setDisplayAdd(true)} disabled={displayAdd}>
            <Falcon icon="plus"/>
          </IconButton>
        </ButtonWrapper>}/>);
}
