import { 
// Plugins
Alignment, Autosave, BalloonToolbar, Bold, CloudServices, EasyImage, Essentials, FontSize, FontColor, FontBackgroundColor, Heading, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, Indent, IndentBlock, Italic, Link, List, AdjacentListsSupport, Notification, PageBreak, Paragraph, RestrictedEditingMode, Strikethrough, Table, TableToolbar, TableCaption, TableColumnResize, TableProperties, TableCellProperties, Underline, DecoupledEditor, } from 'ckeditor5';
import { Comments, Permissions, TrackChanges, RevisionTracker, RevisionHistory, RealTimeCollaborativeEditing, RealTimeCollaborativeComments, RealTimeCollaborativeTrackChanges, RealTimeCollaborativeRevisionHistory, ImportWord, ExportWord, } from 'ckeditor5-premium-features';
import { Assistant } from './plugins/Assistant';
import { AttachmentStore, AttachmentWidgetEditing, InsertAttachmentWidgetEditing, } from './plugins/Attachments';
import { HighlightPlugin } from './plugins/Highlight';
import { ListAttributesExtender } from './plugins/ListAttributesExtender';
import { getColorPickerColors } from './utils/font';
/**
 * A list of plugins that are available on the server. Clients that utilises Real Time Collaboration must include an unmodifed copy of this list.
 */
const pluginList = [
    // Custom
    Assistant,
    HighlightPlugin,
    ListAttributesExtender,
    AttachmentStore,
    AttachmentWidgetEditing,
    InsertAttachmentWidgetEditing,
    // CKEditor
    Alignment,
    Autosave,
    BalloonToolbar,
    Bold,
    CloudServices,
    Comments,
    EasyImage,
    Essentials,
    ExportWord,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImportWord,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    RealTimeCollaborativeEditing,
    RealTimeCollaborativeComments,
    RealTimeCollaborativeRevisionHistory,
    RealTimeCollaborativeTrackChanges,
    RestrictedEditingMode,
    RevisionHistory,
    RevisionTracker,
    Strikethrough,
    Table,
    TableToolbar,
    TableCaption,
    TableColumnResize,
    TableProperties,
    TableCellProperties,
    TrackChanges,
    Underline,
];
DecoupledEditor.defaultConfig = {
    licenseKey: process.env.PLEDITOR_LICENSE_KEY,
};
const editorConfig = {
    licenseKey: process.env.PLEDITOR_LICENSE_KEY,
    // Plugin: Comments
    comments: {
        editorConfig: {},
    },
    // Plugin: TrackChanges
    trackChanges: {
        disableComments: true,
        mergeNestedSuggestions: true,
        trackFormatChanges: 'default',
    },
    // Plugin: Table
    table: {
        defaultHeadings: { rows: 15 },
    },
    // Plugin: FontSize
    fontSize: {
        supportAllValues: true,
        options: [9, 11, 13, 'default', 17, 19, 21],
    },
    // Plugin: FontColor
    fontColor: {
        columns: 12,
        colorPicker: false,
        colors: getColorPickerColors(),
    },
    // Plugin: FontBackgroundColor
    fontBackgroundColor: {
        columns: 12,
        colorPicker: false,
        colors: getColorPickerColors(),
    },
    // Plugin: RestrictedEditingMode
    restrictedEditing: {
        allowedCommands: [],
        allowedAttributes: [],
    },
    // Plugin: IndentBlock
    indentBlock: {
        classes: ['ck-custom-block-indent-1', 'ck-custom-block-indent-2', 'ck-custom-block-indent-3'],
    },
    // Plugin: ImportWord
    importWord: {
        formatting: {
            resets: 'inline',
            defaults: 'inline',
            styles: 'inline',
            comments: 'basic',
        },
    },
    // Plugin: ExportWord
    exportWord: {},
    // Plugin: Image
    image: {
        insert: {
            type: 'auto',
            integrations: ['upload'],
        },
        resizeUnit: '%',
        resizeOptions: [
            {
                name: 'resizeImage:original',
                value: null,
            },
            {
                name: 'resizeImage:80',
                value: '80',
            },
            {
                name: 'resizeImage:60',
                value: '60',
            },
            {
                name: 'resizeImage:40',
                value: '40',
            },
        ],
    },
};
class Pleditor extends DecoupledEditor {
}
Pleditor.builtinPlugins = pluginList;
Pleditor.defaultConfig = editorConfig;
export { Pleditor, editorConfig, pluginList };
