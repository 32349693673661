import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function isDocument(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return true;
        default:
            return false;
    }
}
