import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function TooltipContent(props) {
    const { active, payload, keys } = props;
    const display = active && payload && payload.length;
    if (!display) {
        return null;
    }
    const [slice] = payload || [];
    const label = (slice === null || slice === void 0 ? void 0 : slice.name) !== 'total' ? slice.name : slice.payload.name;
    const breakdowns = keys
        .map(key => {
        const value = slice.payload[key];
        return {
            name: key,
            value,
        };
    })
        .filter(({ value }) => value !== undefined);
    const hasBreakdowns = breakdowns.length > 1;
    const hasCustomTooltip = Boolean(slice.payload.tooltip);
    const breakdownName = slice.payload.name;
    const breakdownValue = slice.payload.total;
    if (hasCustomTooltip) {
        return (<Box bg="primary.900" borderRadius="md" p="lg" gap="xl" flexDirection="column">
        <Typography $appearance="light">
          {label} - {slice.payload.tooltip}
        </Typography>
      </Box>);
    }
    return (<Box bg="primary.900" borderRadius="md" p="lg" gap="xl" flexDirection="column">
      <Box alignItems="center">
        <Box flexDirection="column" gap="xs">
          {hasBreakdowns && (<Typography $fontSize="tiny" $appearance="light" $fontWeight="bold" $lineHeight="neutralized">
              <Trans comment="Insights: Tooltip - Breakdown value">
                {breakdownName} - {breakdownValue} in total
              </Trans>
            </Typography>)}
          <Box gap="sm" alignItems="center">
            <Typography $appearance="light" $fontWeight="bold" $fontSize="large" $lineHeight="neutralized">
              {label}:
            </Typography>
            <Typography $appearance="light" $lineHeight="neutralized" $fontSize="large">
              {slice.value}
            </Typography>
          </Box>
        </Box>

        <Box ml="auto" pl="lg">
          <Falcon icon="arrow-up-right-from-square" $color="text.light"/>
        </Box>
      </Box>

      {hasBreakdowns && (<Box flexDirection="column" gap="md">
          {breakdowns.map(({ name, value }) => (<Typography key={name} $fontSize="small" $appearance="light" $fontWeight="bold" $lineHeight="neutralized">
              {name}: {value} documents
            </Typography>))}
        </Box>)}
    </Box>);
}
