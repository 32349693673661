import { useQuery } from '@apollo/client';
import { getCompanyPlan } from './query';
export function useGetCompanyPlanName(companyId) {
    var _a;
    const { data, loading, error } = useQuery(getCompanyPlan, {
        variables: { companyId: companyId || '' },
    });
    const { plan } = ((_a = data === null || data === void 0 ? void 0 : data.company) === null || _a === void 0 ? void 0 : _a.plan) || {};
    return { plan, loading, error };
}
