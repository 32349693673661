import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuItemText } from '@pocketlaw/tetris';
import { ColumnLabel } from './ColumnLabel';
import { ColumnToggle } from './ColumnToggle';
import { ColumnItemContainer } from './styled';
export function SortableItem(props) {
    const { id, children, disabled, active, onToggle } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
        disabled,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (<ColumnItemContainer as="div" ref={setNodeRef} style={style} disabled={disabled} {...attributes}>
      <ColumnLabel active={active} {...listeners}>
        <MenuItemText primary={children}/>
      </ColumnLabel>
      <ColumnToggle active={active} onClick={onToggle}/>
    </ColumnItemContainer>);
}
