import { breakpoints, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const IconContainer = styled.div `
  display: none;

  [data-icon='falcon'] {
    color: #4d3199;
    width: 1.5rem;
    height: 1.5rem;
  }

  ${breakpoints.lg} {
    display: block;
  }

  ${breakpoints.xl} {
    [data-icon='falcon'] {
      width: 2rem;
      height: 2rem;
    }
  }
`;
export const GradientText = styled.p `
  margin-top: 0;
  line-height: 100%;
  font-size: ${pxToRem(28)};
  font-weight: ${themeGet('fontWeight.bold')};
  letter-spacing: ${pxToRem(-2)};
  padding: ${themeGet('spacing.md')} 0;
  white-space: pre-line;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(35, 20, 85, 1) 20%,
    rgba(52, 29, 98, 1) 30%,
    rgba(77, 49, 153, 1) 39%,
    rgba(16, 9, 31, 1) 76%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${breakpoints.xl} {
    font-size: ${pxToRem(38)};
  }
`;
