import { PROVIDER_IDS } from 'app/domains/esigning/utils';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
export const getEsigningProviderMetadataByProviderId = (id) => {
    switch (id) {
        case PROVIDER_IDS.DOCUSIGN: {
            return {
                properties: {
                    nameFormat: 'fullName',
                    showActiveSignRequestHistory: false,
                    showCreditBalance: false,
                    isRecommended: false,
                    hasExternalAPI: true,
                    selectedByDefault: false,
                    canRemoveSignatory: true,
                    hasFreePlanAccess: true,
                    canUseSigningOrder: false,
                    canUseTitleField: false,
                    canUseEsigningMessages: false,
                    canUseAutomaticReminder: false,
                    canUseEditPendingSignatory: false,
                    maxNumberOfAttachments: null,
                    defaultAuthenticationMode: EsigningProviderAuthenticationModeSlug.Email,
                    advancedAuthenticationModes: [],
                    selectOrder: 3,
                    hasSettings: false,
                },
            };
        }
        case PROVIDER_IDS.POCKETLAW: {
            return {
                properties: {
                    nameFormat: 'firstNameLastName',
                    showActiveSignRequestHistory: true,
                    showCreditBalance: true,
                    isRecommended: true,
                    hasExternalAPI: false,
                    selectedByDefault: true,
                    canRemoveSignatory: true,
                    hasFreePlanAccess: false,
                    canUseSigningOrder: true,
                    canUseTitleField: true,
                    canUseEsigningMessages: true,
                    canUseAutomaticReminder: true,
                    canUseEditPendingSignatory: true,
                    maxNumberOfAttachments: 20,
                    defaultAuthenticationMode: EsigningProviderAuthenticationModeSlug.Email,
                    advancedAuthenticationModes: [
                        EsigningProviderAuthenticationModeSlug.NorBankid,
                        EsigningProviderAuthenticationModeSlug.SweBankid,
                        EsigningProviderAuthenticationModeSlug.SmsOtp,
                        EsigningProviderAuthenticationModeSlug.SmartId,
                        EsigningProviderAuthenticationModeSlug.Mitid,
                    ],
                    selectOrder: 1,
                    hasSettings: true,
                },
            };
        }
        case PROVIDER_IDS.SCRIVE: {
            return {
                properties: {
                    nameFormat: 'fullName',
                    showActiveSignRequestHistory: false,
                    showCreditBalance: false,
                    isRecommended: false,
                    hasExternalAPI: true,
                    selectedByDefault: false,
                    canRemoveSignatory: false,
                    hasFreePlanAccess: true,
                    canUseSigningOrder: false,
                    canUseTitleField: false,
                    canUseEsigningMessages: false,
                    canUseAutomaticReminder: false,
                    canUseEditPendingSignatory: false,
                    maxNumberOfAttachments: null,
                    defaultAuthenticationMode: EsigningProviderAuthenticationModeSlug.Email,
                    advancedAuthenticationModes: [EsigningProviderAuthenticationModeSlug.SweBankid],
                    selectOrder: 2,
                    hasSettings: false,
                },
            };
        }
        default: {
            return {
                properties: {
                    nameFormat: 'fullName',
                    showActiveSignRequestHistory: false,
                    showCreditBalance: false,
                    isRecommended: false,
                    hasExternalAPI: false,
                    selectedByDefault: false,
                    canRemoveSignatory: false,
                    hasFreePlanAccess: false,
                    canUseSigningOrder: false,
                    canUseTitleField: false,
                    canUseEsigningMessages: false,
                    canUseAutomaticReminder: false,
                    canUseEditPendingSignatory: false,
                    maxNumberOfAttachments: null,
                    defaultAuthenticationMode: EsigningProviderAuthenticationModeSlug.Email,
                    advancedAuthenticationModes: [],
                    selectOrder: 999,
                    hasSettings: false,
                },
            };
        }
    }
};
