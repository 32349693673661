var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Alert, Button, DialogContent, DialogFooter, DialogHeader, GridCell, GridRow, Loader, Typography, } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useCreateApprovalRequest } from 'app/domains/esigning/hooks/useCreateApprovalRequest';
import { useLastApprovalRequest } from 'app/domains/esigning/hooks/useLastApprovalRequest';
import { ApprovalErrorSubcodes } from 'app/domains/esigning/utils';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { FormDialog } from 'shared/domains/forms';
import { ApproversPermissionList } from './ApproversPermissionList';
import { MessageField } from './MessageField';
const APPROVAL_ERROR_SUBCODES = [
    ApprovalErrorSubcodes.AlreadyPending,
    ApprovalErrorSubcodes.AlreadyApproved,
];
export function RequestApprovalDialog(props) {
    const { approvalType, approversWithoutPermission, selectedApproverUserIds, onClose } = props;
    const { documentId } = useEsigning();
    const { createApprovalRequest } = useCreateApprovalRequest();
    const { refetch } = useLastApprovalRequest(documentId);
    const { isRtcAvailable } = usePreview();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isApprovalStatusChanged, setIsApprovalStatusChanged] = useState(false);
    const filteredApproversWithoutPermission = approversWithoutPermission.filter(approver => approver.userId && (selectedApproverUserIds === null || selectedApproverUserIds === void 0 ? void 0 : selectedApproverUserIds.includes(approver.userId)));
    const approverIdsWithoutPermission = filteredApproversWithoutPermission
        .map(approver => approver.userId)
        .filter((id) => id !== undefined || id !== null);
    const handleClose = () => {
        if (isApprovalStatusChanged) {
            refetch();
        }
        onClose();
    };
    const handleError = (error) => {
        var _a;
        setLoading(false);
        if (error instanceof ApolloError) {
            const subcode = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions.subcode;
            if (subcode && APPROVAL_ERROR_SUBCODES.includes(subcode)) {
                setIsApprovalStatusChanged(true);
                if (subcode === ApprovalErrorSubcodes.AlreadyPending) {
                    return setAlert({
                        appearance: 'info',
                        message: t({
                            comment: 'Handle ask for approval request dialog error message - Document approval already pending error',
                            message: 'Someone has already asked for approval for this document',
                        }),
                    });
                }
                if (subcode === ApprovalErrorSubcodes.AlreadyApproved) {
                    return setAlert({
                        appearance: 'info',
                        message: t({
                            comment: 'Handle ask for approval request dialog error message - Document approval already approved error',
                            message: 'This document has already been approved.',
                        }),
                    });
                }
            }
        }
        return setAlert({
            appearance: 'danger',
            message: t({
                comment: 'Handle ask for approval request dialog error message - Generic error message',
                message: 'Something went wrong while asking for approval, please try again.',
            }),
        });
    };
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        setAlert(null);
        const selectedUserIds = approvalType === ApprovalType.All ? undefined : selectedApproverUserIds;
        try {
            yield createApprovalRequest(documentId, approverIdsWithoutPermission, selectedUserIds, values.message);
            if (isRtcAvailable) {
                documentFlush({ state: 'initialized' });
            }
            handleClose();
        }
        catch (error) {
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            handleError(error);
        }
    });
    const initialValues = {
        message: undefined,
    };
    return (<FormDialog size="sm" onClose={handleClose} onSubmit={handleSubmit} initialValues={initialValues}>
      <DialogHeader title={t({
            comment: 'Ask for approval dialog title',
            message: 'Ask for Approval',
        })}/>
      <DialogContent>
        <GridRow>
          <GridCell width={12}>
            <Typography>
              <Trans comment="Ask for approval dialog body">
                When the assigned approvers have approved the document you will be able to send it
                for e-signing
              </Trans>
            </Typography>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell width={12}>
            <MessageField />
          </GridCell>
        </GridRow>
        {filteredApproversWithoutPermission.length > 0 && (<GridRow>
            <GridCell width={12}>
              <ApproversPermissionList approvers={filteredApproversWithoutPermission}/>
            </GridCell>
          </GridRow>)}
        {alert && (<GridRow>
            <GridCell width={12}>
              <Alert {...alert}/>
            </GridCell>
          </GridRow>)}
      </DialogContent>
      <DialogFooter>
        {isApprovalStatusChanged ? (<Button appearance="primary" onClick={handleClose}>
            <Trans comment="Ask for approval dialog close button label">Close</Trans>
          </Button>) : (<Fragment>
            <Button appearance="secondary" disabled={loading} onClick={handleClose}>
              <Trans comment="Ask for approval dialog cancel button label">Cancel</Trans>
            </Button>
            <Button appearance="primary" disabled={loading} type="submit">
              <Trans comment="Ask for approval dialog send for approval button label">
                Send for approval
              </Trans>
              {loading && <Loader />}
            </Button>
          </Fragment>)}
      </DialogFooter>
    </FormDialog>);
}
