import { gql } from '@apollo/client';
export const userAccessQuery = gql `
  query userAccessQuery($id: ID!) {
    entry: fileSystemEntry(id: $id) {
      id
      name
      parentId
      acl: acls {
        id
        userId
        actions
      }
    }
  }
`;
