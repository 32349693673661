import { useLazyQuery } from '@apollo/client';
import { query } from './query';
/**
 * This hook is used to get a temporary url of a file from the filesystem. Unlike `useFileDownload` you
 * choose which mimeType to use
 */
export function useFileUrl() {
    const [runQuery] = useLazyQuery(query);
    const getFileUrl = (options) => {
        const { documentId, fileName = 'file', mimeType } = options;
        return runQuery({
            variables: {
                documentId,
                fileName,
            },
        }).then(response => {
            var _a, _b, _c, _d, _e;
            return (_e = (_d = (_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.fileSystemDocument) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c.find((file) => file.mimeType === mimeType)) === null || _d === void 0 ? void 0 : _d.signed) === null || _e === void 0 ? void 0 : _e.url;
        });
    };
    return { getFileUrl };
}
