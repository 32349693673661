import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const PopButtonContent = styled.div `
  position: relative;
`;
export const PopButtonContainer = styled.button `
  position: relative;
  display: flex;
  width: 100%;
  text-align: left;
  border: 0;
  outline: 0;
  overflow: hidden;
  padding: ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.lg')};
  background-color: ${themeGet('colors.gray.50')};
  transition: all 225ms ease-out;

  color: ${themeGet('colors.text.300')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.500')};
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110%;
    height: 400%;
    background: linear-gradient(
      320deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(24, 17, 46, 1) 20%,
      rgba(34, 13, 75, 1) 30%,
      rgba(49, 22, 121, 1) 35%,
      rgba(16, 9, 31, 1) 80%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0;
    transform: translate3d(-50%, -50%, 0);
    transition:
      opacity 225ms ease-out,
      left 355ms ease-out,
      width 555ms ease-out 50ms;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    z-index: 1;
    transform: translateY(-${pxToRem(-2)});
    box-shadow: ${themeGet('dropShadow.200')};
  }

  &:is(:hover):not(:disabled, :active) {
    z-index: 1;
    transform: translateY(-${pxToRem(2)});
    box-shadow: ${themeGet('dropShadow.400')};
  }

  &:is(:hover):not(:disabled) {
    cursor: pointer;
    color: ${themeGet('colors.text.light')};

    [data-icon='falcon'] {
      color: inherit;
    }

    &::before {
      opacity: 1;
      left: 60%;
      width: 400%;
      height: 100%;
    }
  }
`;
