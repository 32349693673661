import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { GetApproversInDocumentSettingsDocument } from './GetApproversInDocumentSettings.gql';
function dedupe(items) {
    const map = new Map();
    const sortedItems = items.toSorted((a, b) => a.approvalOrder - b.approvalOrder);
    sortedItems.forEach(item => {
        if (!map.has(item.userId)) {
            map.set(item.userId, item);
        }
    });
    return Array.from(map.values());
}
export function useGetApprovers() {
    var _a;
    const { id } = useActiveDocument();
    const { data, loading, refetch } = useQuery(GetApproversInDocumentSettingsDocument, {
        variables: {
            id,
        },
    });
    const { approvers = [], type, teamApprovers = [] } = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.approval) || {};
    const documentApprovers = [
        ...teamApprovers.flatMap(teamApprover => {
            var _a, _b;
            const users = ((_b = (_a = teamApprover === null || teamApprover === void 0 ? void 0 : teamApprover.team) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.map(member => member.user)) || [];
            return users.map(user => ({
                approvalOrder: teamApprover.approvalOrder || 0,
                userId: (user === null || user === void 0 ? void 0 : user.id) || '',
                name: (user === null || user === void 0 ? void 0 : user.name) || '',
            }));
        }),
        ...approvers.map(approver => {
            var _a, _b;
            return ({
                approvalOrder: approver.approvalOrder || 0,
                userId: ((_a = approver.user) === null || _a === void 0 ? void 0 : _a.id) || '',
                name: ((_b = approver.user) === null || _b === void 0 ? void 0 : _b.name) || '',
            });
        }),
    ];
    return { loading, approvers: dedupe(documentApprovers), type, refetch };
}
