import { useMobile } from 'shared/domains/common-ui';
import { Container, Navigation } from './styled';
function MobileNavigation(props) {
    const { children } = props;
    const isMobile = useMobile();
    if (isMobile === false) {
        return null;
    }
    return (<Container>
      <Navigation>{children}</Navigation>
    </Container>);
}
export default MobileNavigation;
