import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const IconContainer = styled.div `
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 175ms ease-out;

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.500')};
    transition: inherit;
  }
`;
export const Container = styled.button `
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.lg')};
  background-color: ${themeGet('colors.gray.50')};
  transition: all 175ms ease-out;

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${themeGet('colors.gray.100')};

      ${IconContainer} {
        transform: translateX(${pxToRem(6)});

        [data-icon='falcon'] {
          color: ${themeGet('colors.gray.700')};
        }
      }
    }

    &:active {
      background-color: ${themeGet('colors.gray.200')};
    }
  }
`;
