import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PopButton } from '../PopButton';
export function HistoryButton(props) {
    const { disabled, onClick } = props;
    return (<PopButton disabled={disabled} icon={<Falcon icon="comments"/>} label={t({
            comment: 'Assistant chat dashboard - history button label',
            message: 'History',
        })} onClick={onClick}/>);
}
