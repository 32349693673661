import { gql } from '@apollo/client';
export const getCompanyPlan = gql `
  query companyPlan($companyId: UUID!) {
    company(id: $companyId) {
      plan {
        id
        plan {
          id
          name
        }
      }
    }
  }
`;
